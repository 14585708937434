.btn-details-targeta {
    border: none;
    background: transparent !important;
    color:rgb(0, 70, 247)
}

.row-targetas {
    display: grid;
    
    // grid-auto-rows: minmax(60px, auto);
    // grid-template-columns:100%; 
    align-items: center;

    div {
      background: #0f1721;
      border-radius: 3px;
      text-align:center;
      border-bottom:1px solid #e8cf90;
      padding: 5px;
      font-size: 12px;
      transition:all 500ms ease;
      
    } 

    div:hover {
      transition:all 500ms ease;
      background: #001fb74c;
      
    }


    strong {
      transition: all 500ms ease;
    }
 


    p {
      margin: auto auto auto 0;

    }


  }


.btn-close-file {
    position: absolute;
    top: 5%;
    right: 7%;
}
.line-fix-targeta {
    display: inline-flex;
    align-items: center;
    justify-content: start;
}


.form-tarjeta-vb {
    border: 1px solid #4490f4 !important;
    border-radius: 5px;
  }
  .control-vb {
    background: rgba(15, 17, 30, 0.423) !important;
    border-style: none !important;
    border-radius: 2px !important;
    box-shadow: none !important;
    color: rgb(14, 51, 153) !important;
    border-bottom: 1px solid #4490f4 !important;
    padding: 10px 10px;
    transition: all 200ms ease;
  }
  
  .control-vb:hover {
    border: 1px solid rgb(0, 90, 244) !important;
    color: #fff !important;
    background: rgb(14, 51, 153) !important;
    border-radius: 2px !important;
    transition: all 220ms ease;
  }
  
  .control-vb:focus {
    background: rgb(255, 255, 255) !important;
    border-style: none !important;
    border-radius: 2px !important;
    box-shadow: none !important;
    border: 1px solid rgb(85, 147, 255) !important;
    color: rgb(14, 51, 153) !important;
  }
  
  .control-vb option {
    background: rgba(14, 51, 153, 0.2) !important;
    border-style: none !important;
    border-radius: 2px !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgb(85, 147, 255) !important;
    width: auto !important;
    max-width: 100% !important;
  }
  
  .animation-targetas-up {
    animation: animation-tarjeta-trans 550ms ease-in-out 0ms 1 normal;
    background-color: transparent !important;
  }
  
  @keyframes animation-tarjeta-trans {
    from {
      margin-top: 4px;
      opacity: 0;
      background-size: 20%;
      transform: translateX(1000px);
      background-color: transparent !important;
    }
  
    to {
      margin-top: 0px;
      opacity: 1;
      background-size: 100%;
      background-color: transparent !important;
    }
  }
  
  .value-description {
    color: rgb(85, 147, 255);
    font-size: 20px !important;
    background: #ffffff16;
    padding: 0.5rem;
  }
  
  .btn-back-tarjetas {
    height: 50px;
    width: 50px;
    display: grid;
    text-align: center;
    align-items: center;
    border-radius: 1px;
    color: #fff;
    transition: 100ms ease;
    cursor: pointer;
    border-style: none;
    transition: all 500ms ease-in-out;
  }
  .btn-back-tarjetas:hover {
    background: #3d60ff;
    transition: 100ms ease;
    cursor: pointer;
  }
  
  .btn-back-tarjetas i:hover {
    transition: all 500ms ease-in-out;
    transform: rotate(180deg);
  }
  
  .section-export-tarjetas p {
    font-size: 18px;
    color: rgb(255, 255, 255);
  }
  
  .section-export-tarjetas button {
    background-color: #0066ff;
    color: #fff;
    border: none;
  }
  
  .section-export-tarjetas button:hover {
    transition: all 140ms;
    background: #4490f4;
  }
  
  .custom-scroll-tarjetas {
    max-height: 280px; /* Set a maximum height to enable vertical scrolling */
    overflow-y: scroll;
  }
  
  .transaction-history-tarjetas {
    background: rgba(68, 144, 244, 0.5);
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
  
  .amount-value-tarjeta {
    font-size: 22px !important;
  }
  
  .date-value-tarjeta {
    font-size: 10px !important;
    margin-left: auto !important;
  }
  
  .input-size-jimf label {
    color: #fff !important;
  }
  
  .error-message-tarjeta {
    position: absolute;
    z-index: 9999;
    background: #ffb3b3;
    color: red;
    padding: 2rem;
    border-radius: 0.5rem;
    left: 25%;
    top: 50%;
    font-size: 16px;
    border: 1px solid red;
    transition: all 500s ease-in-out;
    opacity: 0;
    transform: translateX("0px");
  }
  
  .error-message-static-targeta {
    transform: translateX("20px");
    opacity: 1;
  }
  
  .box-data-complete-transfer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    margin: auto;
    justify-content: center;
  
    @media screen and (min-width: 0px) and (max-width: 992px) {
      width: 100%;
    }
  }
  
  .box-data-complete-transfer input {
    border-style: none !important;
    background: rgba(15, 17, 30, 0.423) !important;
    border-radius: 2px 2px 0px 0px !important;
    color: #ffd !important;
    border-bottom: 1px solid #4490f4 !important;
    padding: 10px 10px;
  }
  
  
  .tittle-box-transfer {
  
    h3 {
        color: rgba(55, 132, 255, 0.864);
        text-align: center;
        font-size: 15px;
  
        strong {
            color: #fff;
        }
        
    }
    
    h2  {
        display: grid;
        margin:0;
        width: 100%;
        grid-template-columns: 100%;
        text-align: center;
        
        padding: 20px;
        border-bottom: 1px solid #4490f4;
    }
  
  
  }
  