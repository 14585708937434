.card-tasks3 {
  height: 557px;
} 


.card-panther-blu {
  
  background:linear-gradient(to bottom left, #003a81, #010543eb), url(./bg-panama.jpg);
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat;

  transition: all 500ms ease;
  color: #fff;
  border: 1px solid #1394e5b4;
  box-shadow: 0px 0px 20px #141fbbb2;
  border-radius: 15px;
} 


.card-panther-blu-dos {
  
  background:linear-gradient(to bottom left, #0b2b83, #0b2b83ee), url(./bg-panama.jpg);
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat;

  transition: all 500ms ease;
  color: #fff;
  border: 1px solid #1367e5;
  box-shadow: 0px 0px 20px #141fbbb2;
} 



.card-panther-blu-grafica {
  
  background:linear-gradient(to bottom left, #020714, #0b2b83ee), url(./bg-panama.jpg);
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat;

  transition: all 500ms ease;
  color: #fff;
  border: 1px solid #f7e8c782;
  
}



.card-panther-blu-dos:hover {
  transform: scale(1.01); 
  
  transition: all 500ms ease;
  box-shadow: 0px 0px 7px #141fbbb2;
  
}



.card-panther-blu:hover {
  transform: scale(1.01); 
  
  transition: all 500ms ease;
  box-shadow: 0px 0px 7px #141fbbb2;
  
}


.montoless {
  animation: montoless 3500ms linear 0ms infinite alternate;
  color:#ff7991 !important;
  
} 


.montoplus {
  animation: montoplus 1500ms linear 17ms infinite alternate;
  color:#00ff40 !important;
  
  
} 


.montototal {
  
  animation: montototal 4500ms linear 17ms infinite alternate;
} 

@keyframes montototal {

  0%{ 
    color:#fcfcfc4d;

  }

100%{
    color:#dcfffa;      
  }


} 

@keyframes montoless {

    0%{ 
      color:#ffffff27;

    }

100%{
      color:#ac174b90;      
    }


} 


@keyframes montoplus {

  0%{ 
    color:#41f56e1e;

  }

100%{
    color:#41f56e48;
  }


} 


select {
  -webkit-appearance: none !important;
} 

input {
  -webkit-appearance: none !important;
} 

label {
  -webkit-appearance: none !important;
} 

.mercadoprimario-style {
  
  font-size: 10px; 
  transition: all 100ms ease-out;

  &:hover{
    
    transition: all 300ms ease;
    cursor: pointer;
  }
} 


.panther-input-file {
  
  font-size: 14px !important;
  width: 100%;
  
  border-radius: 4px;
  padding: 10px 18px 10px 18px;
  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  border:1px solid $info;
  
  &:focus {
    border-color: #2ae69e;
    background-color: transparent;
    box-shadow: none; 
    border:1px solid;
    
      
    
} 


  i {
    font-size: 23px !important;
  }

} 

.btn-radius-panther {
  border-radius:$border-radius-p7;
} 


.custom-file-input-panther {
  cursor:pointer !important;

  &:hover{
    box-shadow: 0px 0px 20px #000;
  }
} 


.acc-config-panther { 

  padding: 0px 0px 0px 0px;

  ul {
        padding: 0px 0px 0px 20px;
    li{
      
        padding:7px 0px 3px 0px;
        font-size: 16px;
        list-style:none; 
        font-weight: 300; 
        border-top: 1px solid $info;
        letter-spacing: 1px;

        strong {
          font-weight: 700;
        }

        span {
          border-radius: 100px;
          background: $success;
          padding: 4px 15px 4px 15px;
          margin: 0px 10px 0px 10px;
        }

    }

  } 

  @media screen and(min-width:0px) and (max-width:500px) {
      
    ul {
      padding: 0px 0px 0px 0px;
      margin-bottom: 10px;
  li{
    
      padding:3px 0px 3px 0px;
      font-size: 16px;
      list-style:none; 
      font-weight: 300; 
      border-top: 1px solid $info;

      strong {
        font-weight: 700;
      }

      span {
        border-radius: 100px;
        background: $success;
        padding: 4px 15px 4px 15px;
        margin: 0px 10px 0px 10px;
      }

  }

} 
  }
} 

.edithbtn {
  margin:0px !important;
  padding: 3px 15px 3px 15px !important;
} 


.btn-blu {
  height:50px;
  justify-content: center;
  flex-direction: column;
  display: flex;

  button {
    background:linear-gradient(to bottom left, #0047ff, #01209b, #0b49ea);
    padding: 10px !important; 

    &:hover {
      background:linear-gradient(to bottom left, #0047ff, #01209b, #0b49ea) !important; 
    }

  }
  
  


} 



.btn-blu-password {
  height:130px;
  justify-content: center;
  flex-direction: column;
  display: flex;

  button {
    background:linear-gradient(to bottom left, #0047ff, #01209b, #0b49ea);
    padding: 10px !important; 

    &:hover {
      background:linear-gradient(to bottom left, #0047ff, #01209b, #0b49ea) !important; 
    }

  }
  
  


} 

.kyc-container {
  height: 300px;
  width: 100%;
  justify-content: center;
  display: flex; 
  background: #fff; 
  border-radius: 20px; 
  box-shadow: 0px 0px 24px $info;
  

} 




.form-range {
    display: block;
    width: 100%;
    height: 8px; 
    border-color: #3969f7;
    border-radius: 0.4285rem;
    font-size: 2.064rem;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    padding: 0px !important;
    text-shadow: 0px 0px 1px #000;
    position: relative;
    &:hover{
      cursor:pointer;
    }
}  


.btn-0 {
  padding: 0 !important;
  margin: 0 !important;
} 


.btn-password-x {
  padding: 10px 10px 10px 10px !important;
  margin: 1rem !important;
  font-size: 30px;
  border-radius: 3px !important;
  font-weight: 300;
} 

.bg-border{
  border-bottom: 1px solid $primary !important;
  background: linear-gradient( to bottom right, rgba(0, 66, 209, 0.241), rgb(5, 51, 136));
  box-shadow: 0px 0px 4px rgb(17, 89, 182);
} 


.datatable-style {
  background: linear-gradient(to bottom, #002f68, #001631) !important;
  color: #fff;
} 

.rdt_Table {
  border-radius: 100px;
} 

.rdt_TableHead {
  background-color: #01209b !important;
} 



//// la tabla , react boostrap table, tabla de inversiones


.react-bootstrap-table { 
  
  

  :hover {
    color:#ffff;
  }

  ul{
    transition: 200ms ease;
  }

  cursor:pointer!important;
  padding:15px;
  
  /// celdas individuales
  table td {
    font-weight: 200;
    box-shadow:none !important;

    :hover{
      border:0px !important;
    }
    
  } 
  
  /// filas
  table tr {
    
    
    
    
    cursor:pointer!important;
    :hover{
      
      border:0px !important;
    }
    

  } 

  tr:nth-child(even) {
    background-color: #162c4972;
  }


  /// tabla completa
  table{
    overflow-x: auto !important;
  white-space:nowrap !important;
    
    border: none !important;
    
    
    cursor:pointer!important;
    :hover{
      border:0px !important;
    }
    
  }

  thead{
    text-align:right; 
    font-size:20px !important;
    border-bottom: 1px solid $info;
    background: linear-gradient( to left, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.083));
    

    :hover {
      border-bottom: 1px solid $info;
    }

  }

  tbody{
    font-size:14px;
    text-align:center; 
    cursor:pointer!important;
    
    :hover{
     
      
      background: #08144646;
      
      
      box-shadow:0px 0px 2px $info;
      
      

     } 
  }

  
  

} 


.react-bootstrap-table-pagination{
  display: flex;
  justify-content: center;

  div{
    justify-content: center;
     display: flex;
     padding: 20px 0px 20px 0px ;
     
  }

  button {
    border-radius: 2px;
    background: $primary !important;

    :hover{
      background-image: $primary !important;
    }

    :focus{
      background-image: none !important;
    }
  }

} 



.tabla_ultimosmov {

  thead{
    background:  linear-gradient(to right, #003185,#062457) ;
    border:1px solid #0066ff;
    
    
  }

  tbody{
    background: #0b1127 ;
    
  } 

  tr:nth-child(even) {
    background: linear-gradient(to left, #003ba0,#031027);
  } 

  tr:hover {
    background:  linear-gradient(to right, #0053e3,#031027) ;
    transform: scale(1.01);
    border:1px solid #0066ff;
  }

  tr td {
    font-size: 14px;
    transition: 800ms all ease;
    padding: 8px 10px !important;
  }

  tr td:hover {
    font-size: 20px;
    transition: 800ms all ease;
    padding: 20px 10px !important;
  }


  th:nth-child(1) { 
      
    text-align:center;
    } 


    th:nth-child(2) { 
    
    text-align:center;
    } 

    
    th:nth-child(3) { 
      
      text-align:left;
      } 

      th:nth-child(4) { 
        
        text-align:right;
        } 

    th:nth-child(5) { 
      
      text-align:right;
      }

  //// aqui empieza el cuerpo
    td:nth-child(1) { 
      
      text-align:center;
      } 
  
  
      td:nth-child(2) { 
      
      text-align:center;
      } 
  
      
      td:nth-child(3) { 
        
        text-align:left;
        font-weight: 500;
        font-size: 12px;
        text-transform: lowercase;
        } 
  
        td:nth-child(4) { 
          
          text-align:right;
          } 
  
      td:nth-child(5) { 
        
        text-align:right;
        }
  
  
  
}


.tabla_listretiros {
  
  td:nth-child(1) { 
    
    text-align:center;
    } 


    td:nth-child(2) { 
    
    text-align:center;
    } 

    
    td:nth-child(3) { 
      
      text-align:left;
      } 

      td:nth-child(4) { 
        
        text-align:center;
        } 

    td:nth-child(5) { 
      
      text-align:right;
      }

      td:nth-child(6) { 
      
        text-align:center;
        }


}



.tabla_beneficiarios {
  
  .td-help-ben {
    align-items: center !important;
      display: flex !important;
      justify-content: center !important ;
  }

} 


.thead-pro{ 

  tr  {
    background: #000 !important;
   
   th:nth-child(1):hover::after { 
     background: linear-gradient( to bottom left,  #071535, #013152);
     box-shadow: 0px 0px 1px #01209b;
     width: 250px;
     height: 60px;
     padding:10px 18px 10px 18px;
     border-radius: 100px;
     position: absolute;
     font-size: 13px;
     content:"Aquí encontraras el Código unico de la oferta ";
     white-space: pre-wrap;
     text-align:left;
     top:-70px;
     font-weight: 400;
     left:0%;
     color:#fff;
     transition: all 50ms ease;
     text-transform: initial;
     border:1px solid #3969f7 ;

     } 
     th:nth-child(2):hover::after { 
       background: linear-gradient( to bottom left,  #071535, #013152);
       box-shadow: 0px 0px 1px #01209b;
       width: 250px;
       height: 60px;
       padding:10px 18px 10px 18px;
       border-radius: 100px;
       position: absolute;
     font-size: 13px;
       content:"país donde se encuentra la participación";
       white-space: pre-wrap;
     text-align:left;
     top:-70px;
     font-weight: 400;
     left:5%;
     color:#fff;
     transition: all 50ms ease;
     text-transform: initial;
     border:1px solid #3969f7;

       } 
       th:nth-child(3):hover::after { 
         background: linear-gradient( to bottom left,  #071535, #013152);
       box-shadow: 0px 0px 1px #01209b;
       width: 250px;
       height: 60px;
       padding:10px 18px 10px 18px;
       border-radius: 100px;
       position: absolute;
     font-size: 13px;
         content:"Representa el tipo de la participación";
         white-space: pre-wrap;
         text-align:left;
         top:-70px;
         font-weight: 400;
         left:8%;
         color:#fff;
         transition: all 50ms ease;
         text-transform: initial;
         border:1px solid #3969f7;
 
         } 
         th:nth-child(4):hover::after { 
           background: linear-gradient( to bottom left,  #071535, #013152);
           box-shadow: 0px 0px 10px #b5f1ff35;
           width: 250px;
           height: 60px;
           padding:10px 18px 10px 18px;
           border-radius: 100px;
           position: absolute;
           font-size: 13px;
           content:"Representa la Categoria de la participación";
           white-space: pre-wrap;
           text-align:left;
           top:-70px;
           font-weight: 400;
           left:14%;
           color:#fff;
           transition: all 50ms ease;
           text-transform: initial;
           border:1px solid #3969f7;
   
           } 
           th:nth-child(5):hover::after { 
             background: linear-gradient( to bottom left,  #071535, #013152);
             box-shadow: 0px 0px 1px #01209b;
             width: 250px;
             height: 60px;
             padding:10px 18px 10px 18px;
             border-radius: 100px;
             position: absolute;
             font-size: 13px;
             content:"el nombre del ofertante de la inversión";
             white-space: pre-wrap;
               text-align:left;
               top:-70px;
               font-weight: 400;
               left:20%;
               color:#fff;
               transition: all 50ms ease;
               text-transform: initial;
               border:1px solid #3969f7;
     
             } 
             th:nth-child(6):hover::after { 
               background: linear-gradient( to bottom left,  #071535, #013152);
               box-shadow: 0px 0px 1px #01209b;
               width: 250px;
               height: 60px;
               padding:10px 18px 10px 18px;
               border-radius: 100px;
               position: absolute;
               font-size: 13px;
               content:"Aquí encontraras el nombre de la inversion o contrato";
               white-space: pre-wrap;
               text-align:left;
               top:-70px;
               font-weight: 400;
               left:30%;
               color:#fff;
               transition: all 50ms ease;
               text-transform: initial;
               border:1px solid #3969f7;
       
               } th:nth-child(7):hover::after { 
                 background: linear-gradient( to bottom left,  #071535, #013152);
                   box-shadow: 0px 0px 1px #01209b;
                   width: 250px;
                   height: 60px;
                   padding:10px 18px 10px 18px;
                   border-radius: 100px;
                   position: absolute;
                   font-size: 13px;
                 content:"Es la clasificación de riesgo de la inversión";
                 white-space: pre-wrap;
                 text-align:left;
                 top:-70px;
                 font-weight: 400;
                 left:45%;
                 color:#fff;
                 transition: all 50ms ease;
                 text-transform: initial;
                 border:1px solid #3969f7;
         
                 }  

                 th:nth-child(8):hover::after { 
                   background: linear-gradient( to bottom left,  #071535, #013152);
                   box-shadow: 0px 0px 1px #01209b;
                   width: 250px;
                   height: 60px;
                   padding:10px 18px 10px 18px;
                   border-radius: 100px;
                   position: absolute;
                   font-size: 13px;
                   content:"Si la participación está cubierta o garantizada";
                   white-space: pre-wrap;
                   text-align:left;
                   top:-70px;
                   font-weight: 400;
                   left:50%;
                   color:#fff;
                   transition: all 50ms ease;
                   text-transform: initial;
                   border:1px solid #3969f7;
           
                   } 
                   th:nth-child(9):hover::after { 
                     background: linear-gradient( to bottom left,  #071535, #013152);
                     box-shadow: 0px 0px 1px #01209b;
                     width: 250px;
                     height: 60px;
                     padding:10px 18px 10px 18px;
                     border-radius: 100px;
                     position: absolute;
                   font-size: 13px;
                     content:"Porcentaje de Interes en relación a 1 año";
                     white-space: pre-wrap;
                   text-align:left;
                   top:-70px;
                   font-weight: 400;
                   left:60%;
                   color:#fff;
                   transition: all 50ms ease;
                   text-transform: initial;
                   border:1px solid #3969f7;
             
                     } 
                     th:nth-child(10):hover::after { 
                       background: linear-gradient( to bottom left,  #071535, #013152);
                     box-shadow: 0px 0px 1px #01209b;
                     width: 250px;
                     height: 60px;
                     padding:10px 18px 10px 18px;
                     border-radius: 100px;
                     position: absolute;
                   font-size: 13px;
                       content:"Periodo de vencimiento disponibilidad de la inversión";
                       white-space: pre-wrap;
                       text-align:left;
                       top:-70px;
                       font-weight: 400;
                       left:65%;
                       color:#fff;
                       transition: all 50ms ease;
                       text-transform: initial;
                       border:1px solid #3969f7;
               
                       } 
                       th:nth-child(11):hover::after { 
                         background: linear-gradient( to bottom left,  #071535, #013152);
                         box-shadow: 0px 0px 10px #b5f1ff35;
                         width: 250px;
                         height: 60px;
                         padding:10px 18px 10px 18px;
                         border-radius: 100px;
                         position: absolute;
                         font-size: 13px;
                         content:"Monto total del costo de la inversión completa";
                         white-space: pre-wrap;
                         text-align:left;
                         top:-70px;
                         font-weight: 400;
                         left:70%;
                         color:#fff;
                         transition: all 50ms ease;
                         text-transform: initial;
                         border:1px solid #3969f7;
                 
                         } 
                         th:nth-child(12):hover::after { 
                           background: linear-gradient( to bottom left,  #071535, #013152);
                           box-shadow: 0px 0px 1px #01209b;
                           width: 250px;
                           height: 60px;
                           padding:10px 18px 10px 18px;
                           border-radius: 100px;
                           position: absolute;
                           font-size: 13px;
                           content:"Porcentaje de interes de la ganancia";
                           white-space: pre-wrap;
                             text-align:left;
                             top:-70px;
                             font-weight: 400;
                             left:75%;
                             color:#fff;
                             transition: all 50ms ease;
                             text-transform: initial;
                             border:1px solid #3969f7;
                   
                           } 
                           th:nth-child(13):hover::after { 
                             background: linear-gradient( to bottom left,  #071535, #013152);
                             box-shadow: 0px 0px 1px #01209b;
                             width: 250px;
                             height: 60px;
                             padding:10px 18px 10px 18px;
                             border-radius: 100px;
                             position: absolute;
                             font-size: 13px;
                             content:"Monto disponible para invertir ";
                             white-space: pre-wrap;
                             text-align:left;
                             top:-70px;
                             font-weight: 400;
                             left:80%;
                             color:#fff;
                             transition: all 50ms ease;
                             text-transform: initial;
                             border:1px solid #3969f7;
                     
                             } th:nth-child(14):hover::after { 
                               background: linear-gradient( to bottom left,  #071535, #013152);
                                 box-shadow: 0px 0px 1px #01209b;
                                 width: 250px;
                                 height: 60px;
                                 padding:10px 18px 10px 18px;
                                 border-radius: 100px;
                                 position: absolute;
                                 font-size: 13px;
                               content:"Número de participantes o personas dentro de la inversión";
                               white-space: pre-wrap;
                               text-align:left;
                               top:-70px;
                               font-weight: 400;
                               left:85%;
                               color:#fff;
                               transition: all 50ms ease;
                               text-transform: initial;
                               border:1px solid #3969f7;
                       
                               } 
                               
                 
 
 }

}


.tabla_proyectos_final { 

  



  

  thead{
    
    border:1px solid #0066ff;
    background:linear-gradient(to right, #000000e0,#062457e7), url(./bg-panama.jpg);
    background-size:cover;
    background-repeat:no-repeat;
    transition: all 500ms ease;
    min-height: 200px;
    
    animation: bg-thead-move 8800ms infinite alternate; 

    :hover{
      background: #000 !important;
      cursor:pointer;
      transition: all 500ms linear;

    }
    
    
    
  } 

  
      @keyframes  bg-thead-move {

        0%{
          background-position: 30% 60%;
          background-size: 100%;

        }

        100%{
          background-position: 30% 50%;
          background-size: 150%;
          
        }

      }


  tbody{
    background: #0b1127 ;
    border:none;
    
  } 

  tfoot{
    background:linear-gradient(to right, #0154d2f2,#0453dcf9), url(./bg-panama.jpg);
    animation: bg-thead-move 8800ms infinite alternate;
    background-size:cover;
    background-repeat:no-repeat;
    transition: all 500ms ease;
    min-height: 200px;
    
    

    th:nth-child(1) { 
      
        
      text-align:center;
      letter-spacing: 7px;
      font-weight: 800;

      } 
  
    

    th {
      border:none;
      font-weight: 400;
      font-size: 22px; 
    }
    
  } 

  tr:nth-child(even) {
    background: linear-gradient(to left, #011434,#0743ab);
  } 

  tr:hover {
    background: #fff;
    color: #000 !important;
    td {
      color: #000;
    } 

    td:nth-child(15) {
      color: #fff;
    }
  }

  tr td {
    font-size: 14px;
    transition: 800ms all ease;
    padding: 3px 10px !important;
  }

  tr td:hover {
    
    transition: 800ms all ease;
    cursor:pointer;
    
  }


  th:nth-child(1) { 
    white-space: nowrap;
    i{
      color:  #00ffbb;
      font-size: 10px;
      
    }
      
    text-align:center;
    } 


    th:nth-child(2) { 
      white-space: nowrap;
      i{
        color: #00ffbb;
        font-size: 10px;
      }
    text-align:center;
    } 

    
    th:nth-child(3) { 
      white-space: nowrap;

      i{
        color: #00ffbb;
        font-size: 10px;
      }
      
      text-align:center;
      } 

      th:nth-child(4) { 
        white-space: nowrap;
        i{
          color: #00ffbb;
          font-size: 10px;
        }
        
        text-align:center;
        } 

    th:nth-child(5) { 
      white-space: nowrap;
      i{
        color: #00ffbb;
        font-size: 10px;
      }
      
      text-align:left;
      }

      th:nth-child(6) { 
        white-space: nowrap;
        
        i{
          color: #00ffbb;
          font-size: 10px;
          
        }
      
        text-align:left;
        } 


        th:nth-child(7) { 
          white-space: nowrap;

          i{
            color: #00ffbb;
            font-size: 10px;
          }
      
          text-align:right;
          } 


          th:nth-child(8) { 
            white-space: nowrap;

            i{
              color: #00ffbb;
              font-size: 10px;
            }
      
            text-align:center;
            } 
            th:nth-child(9) { 
              white-space: nowrap;

              i{
                color: #00ffbb;
                font-size: 10px;
              }
        
              text-align:center;
              }
              th:nth-child(10) { 
                white-space: nowrap;

                i{
                  color: #00ffbb;
                  font-size: 10px;
                }
          
                text-align:center;
                }
                th:nth-child(11) { 
                  white-space: nowrap;

                  i{
                    color: #00ffbb;
                    font-size: 10px;
                  }
            
                  text-align:center;
                  }
                  th:nth-child(12) { 
                    white-space: nowrap;

                    i{
                      color: #00ffbb;
                      font-size: 10px;
                    }
              
                    text-align:center;
                    }
                    th:nth-child(13) { 
                      white-space: nowrap;

                      i{
                        color: #00ffbb;
                        font-size: 10px;
                      }
                
                      text-align:center;
                      }
                      th:nth-child(14) { 
                        white-space: nowrap;

                        i{
                          color: #00ffbb;
                          font-size: 10px;
                        }
                  
                        text-align:center;
                        }
                        th:nth-child(15) { 
                          white-space: nowrap;

                          i{
                            color: #00ffbb;
                            font-size: 10px;
                          }
                    
                          text-align:center;
                          }

  //// aqui empieza el cuerpo
    td:nth-child(1) { 
      
      text-align:center;
      text-transform: lowercase;
      font-size: 10px;
      } 
  
  
      td:nth-child(2) { 
      
      text-align:center;
      font-size: 10px;
      font-weight: 900;
      } 
  
      
      td:nth-child(3) { 
        
        text-align:center;
        font-size: 10px;
     
        } 
  
        td:nth-child(4) { 
          
          text-align:center;
          font-size: 10px;
          } 
  
      td:nth-child(5) { 
        white-space: wrap;
        text-align:left;
        font-size: 10px;
        height: 70px;
        } 

        td:nth-child(6) { 
          white-space: wrap;
          text-align:left;
          font-size: 10px;
          height: 70px;
          }
  
          td:nth-child(7) { 
        
            text-align:right;
            font-size: 17px;
            font-weight: 900;
            } 
            td:nth-child(8) { 
        
              text-align:right;
              font-size: 15px;
              font-weight: 900;
              }
              td:nth-child(9) { 
        
                text-align:right;
                font-size: 10px;
                }
                td:nth-child(10) { 
        
                  text-align:center;
                  font-size: 8px;
                  font-weight: 900;
                  
                  }
                  td:nth-child(11) { 
        
                    text-align:right;
                    font-size: 12px;
                    letter-spacing: 2px;
                    }
                    td:nth-child(12) { 
        
                      text-align:right;
                      font-size: 11px;
                      font-weight: 700;
                      }
                      td:nth-child(13) { 
        
                        text-align:right;
                        font-size: 12px;
                        letter-spacing: 2px;
                        }
                        td:nth-child(14) { 
        
                          text-align:center;
                          font-size: 15px;
                          font-weight: 900;
                          }
  
            td:nth-child(15) { 
      
              text-align:center;
              background: #0047ff;
              border-radius: 60px;
              transform: scale(80%);       
              transition: all 500ms ease;
              cursor:pointer !important;
              
         
    
             
    
              } 
    
    
              td:nth-child(15):hover { 
          
                text-align:center;
                background: #00845a !important;
                border-radius: 60px;   
                box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.39) !important;       
                transform: scale(84%);      
               transition: all 500ms ease;
               
      
                }
        
  
  
  
}

.tabla_inversiones { 
  transition: all 50ms ease;
  thead { 
    
    tr { 

      
      
      th:nth-child(1):hover::after { 
        background: linear-gradient( to bottom left,  #071535, #013152);
        box-shadow: 0px 0px 1px #01209b;
        width: 250px;
        height: 60px;
        padding:10px 18px 10px 18px;
        border-radius: 100px;
        position: absolute;
        font-size: 13px;
        content:"En esta casilla encontraras la fecha de inicio de tu inversion ";
        white-space: pre-wrap;
        text-align:left;
        top:-70px;
        font-weight: 400;
        left:2%;
        color:#fff;
        transition: all 50ms ease;
        text-transform: initial;
        border:1px solid #3969f7;

        } 
        th:nth-child(2):hover::after { 
          background: linear-gradient( to bottom left,  #071535, #013152);
          box-shadow: 0px 0px 1px #01209b;
          width: 250px;
          height: 60px;
          padding:10px 18px 10px 18px;
          border-radius: 100px;
          position: absolute;
        font-size: 13px;
          content:"En esta casilla encontraras el nombre del proyecto";
          white-space: pre-wrap;
        text-align:left;
        top:-70px;
        font-weight: 400;
        left:10%;
        color:#fff;
        transition: all 50ms ease;
        text-transform: initial;
        border:1px solid #3969f7;
  
          } 
          th:nth-child(3):hover::after { 
            background: linear-gradient( to bottom left,  #071535, #013152);
          box-shadow: 0px 0px 1px #01209b;
          width: 250px;
          height: 60px;
          padding:10px 18px 10px 18px;
          border-radius: 100px;
          position: absolute;
        font-size: 13px;
            content:"Esto es el monto de la participacion para invertir";
            white-space: pre-wrap;
            text-align:left;
            top:-70px;
            font-weight: 400;
            left:40%;
            color:#fff;
            transition: all 50ms ease;
            text-transform: initial;
            border:1px solid #3969f7;
    
            } 
            th:nth-child(4):hover::after { 
              background: linear-gradient( to bottom left,  #071535, #013152);
              box-shadow: 0px 0px 10px #b5f1ff35;
              width: 250px;
              height: 60px;
              padding:10px 18px 10px 18px;
              border-radius: 100px;
              position: absolute;
              font-size: 13px;
              content:"Esta es la fecha del final de la participacion";
              white-space: pre-wrap;
              text-align:left;
              top:-70px;
              font-weight: 400;
              left:50%;
              color:#fff;
              transition: all 50ms ease;
              text-transform: initial;
              border:1px solid #3969f7;
      
              } 
              th:nth-child(5):hover::after { 
                background: linear-gradient( to bottom left,  #071535, #013152);
                box-shadow: 0px 0px 1px #01209b;
                width: 250px;
                height: 60px;
                padding:10px 18px 10px 18px;
                border-radius: 100px;
                position: absolute;
                font-size: 13px;
                
                content:"En está casilla encontraras el nombre del proyecto";
                white-space: pre-wrap;
                  text-align:left;
                  top:-70px;
                  font-weight: 400;
                  left:65%;
                  color:#fff;
                  transition: all 50ms ease;
                  text-transform: initial;
                  border:1px solid #3969f7;
        
                } 
                th:nth-child(6):hover::after { 
                  background: linear-gradient( to bottom left,  #071535, #013152);
                  box-shadow: 0px 0px 1px #01209b;
                  width: 250px;
                  height: 60px;
                  padding:10px 18px 10px 18px;
                  border-radius: 100px;
                  position: absolute;
                  font-size: 13px;
                  content:"Aquí encontraras el rendimiento actual de tu inversion";
                  white-space: pre-wrap;
                  text-align:left;
                  top:-70px;
                  font-weight: 400;
                  left:80%;
                  color:#fff;
                  transition: all 50ms ease;
                  text-transform: initial;
                  border:1px solid #3969f7;
          
                  } th:nth-child(7):hover::after { 
                    background: linear-gradient( to bottom left,  #071535, #013152);
                      box-shadow: 0px 0px 1px #01209b;
                      width: 250px;
                      height: 60px;
                      padding:10px 18px 10px 18px;
                      border-radius: 100px;
                      position: absolute;
                      font-size: 13px;
                    content:"Aquí podras vender tus inversiones con un Click";
                    white-space: pre-wrap;
                    text-align:left;
                    top:-70px;
                    font-weight: 400;
                    left:85%;
                    color:#fff;
                    transition: all 50ms ease;
                    text-transform: initial;
                    border:1px solid #3969f7;
            
                    } 
                    
    
    }
  }


  td:nth-child(1) { 
    
    text-align:center;
    } 


    td:nth-child(2) { 
    
    text-align:left;
    } 

    
    td:nth-child(3) { 
      
      text-align:right;
      } 

      td:nth-child(4) { 
        
        text-align:center;
        } 

    td:nth-child(5) { 
      
      text-align:right;
      } 

      td:nth-child(6) { 
      
        text-align:right;
        } 

        td:nth-child(7) { 
      
          text-align:center;
          background: #0047ff;
          border-radius: 60px;
          transform: scale(80%);       
          transition: all 500ms ease;
          
     

         

          } 


          td:nth-child(7):hover { 
      
            text-align:center;
            background: #00845a !important;
            border-radius: 60px;   
            box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.39) !important;       
            transform: scale(84%);      
           transition: all 500ms ease;
           
  
            }
    
  

}




.tabla_ofertas_final { 

  
  thead{
    
    
    background:linear-gradient(to right, #000000e0,#062457e7), url(./bg-panama.jpg);
    background-size:cover;
    background-repeat:no-repeat;
    transition: all 500ms ease;
    min-height: 200px;
    
    animation: bg-thead-move 8800ms infinite alternate;
    
    
  } 

  
      @keyframes  bg-thead-move {

        0%{
          background-position: 30% 60%;
          background-size: 100%;

        }

        100%{
          background-position: 30% 50%;
          background-size: 150%;
          
        }

      }


  tbody{
    background: #0b1127 ;
    border:none;
    
  } 
  
  tfoot{
    background:linear-gradient(to right, #0154d2f2,#0453dcf9), url(./bg-panama.jpg);
    animation: bg-thead-move 8800ms infinite alternate;
    background-size:cover;
    background-repeat:no-repeat;
    transition: all 500ms ease;
    min-height: 200px;
    
    

    th:nth-child(1) { 
      
        
      text-align:center;
      letter-spacing: 7px;
      font-weight: 800;

      } 
  
    

    th {
      border:none;
      font-weight: 400;
      font-size: 22px; 
    }
    
  } 


  tr:nth-child(even) {
    background: linear-gradient(to left, #011434,#0743ab);
  } 

 

  tr td {
    font-size: 14px;
    transition: 800ms all ease;
    padding: 8px 10px !important;
  }

  tr td:hover {
    
    transition: 800ms all ease;
    
  }


  
  th:nth-child(1) { 
    white-space: nowrap;
    i{
      color:  #00ffbb;
      font-size: 15px;
    }
      
    text-align:center;
    } 


    th:nth-child(2) { 
      white-space: nowrap;
      i{
        color: #00ffbb;
        font-size: 15px;
      }
    text-align:center;
    } 

    
    th:nth-child(3) { 
      white-space: nowrap;

      i{
        color: #00ffbb;
        font-size: 15px;
      }
      
      text-align:center;
      } 

      th:nth-child(4) { 
        white-space: nowrap;
        i{
          color: #00ffbb;
          font-size: 15px;
        }
        
        text-align:right;
        } 

    th:nth-child(5) { 
      white-space: nowrap;
      i{
        color: #00ffbb;
        font-size: 15px;
      }
      
      text-align:right;
      }

      th:nth-child(6) { 
        white-space: nowrap;
        i{
          color: #00ffbb;
          font-size: 15px;
        }
      
        text-align:right;
        } 


        th:nth-child(7) { 
          white-space: nowrap;

          i{
            color: #00ffbb;
            font-size: 15px;
          }
      
          text-align:right;
          } 


          th:nth-child(8) { 
            white-space: nowrap;


            i{
              color: #00ffbb;
              font-size: 15px;
            }
      
            text-align:center;
            }

  //// aqui empieza el cuerpo
    td:nth-child(1) { 
      
      text-align:left;
      text-transform: lowercase;
      } 
  
  
      td:nth-child(2) { 
      
      text-align:center;
      } 
  
      
      td:nth-child(3) { 
        
        text-align:center;
     
        } 
  
        td:nth-child(4) { 
          
          text-align:right;
          } 
  
      td:nth-child(5) { 
        
        text-align:right;
        } 

        td:nth-child(6) { 
        
          text-align:right;
          }
  
          td:nth-child(7) { 
        
            text-align:right;
            }
  
            td:nth-child(8) { 
      
              text-align:center;
              transition: all 500ms ease;
              cursor:pointer !important;
              
         
    
             
    
              } 
    
    
              td:nth-child(8):hover {  


                button {
                  text-align:center;
                  background: rgb(255, 0, 68) !important;
                  
                  box-shadow: 0px 0px 10px rgba(230, 230, 230, 0.39) !important;       
                  
                 transition: all 500ms ease;
                }
                
          
                

               
      
                }
        

}



.tabla_inversiones_final { 

  



  

  thead{
    
    
    background:linear-gradient(to right, #000000e0,#062457e7), url(./bg-panama.jpg);
    background-size:cover;
    background-repeat:no-repeat;
    transition: all 500ms ease;
    min-height: 200px;
    
    animation: bg-thead-move 8800ms infinite alternate;
    
    
  } 

  
      @keyframes  bg-thead-move {

        0%{
          background-position: 30% 60%;
          background-size: 100%;

        }

        100%{
          background-position: 30% 50%;
          background-size: 150%;
          
        }

      }


  tbody{
    background: #0b1127 ;
    border:none;
    
  } 
  
  tfoot{
    background:linear-gradient(to right, #0154d2f2,#0453dcf9), url(./bg-panama.jpg);
    animation: bg-thead-move 8800ms infinite alternate;
    background-size:cover;
    background-repeat:no-repeat;
    transition: all 500ms ease;
    min-height: 200px;
    
    

    th:nth-child(1) { 
      
        
      text-align:center;
      letter-spacing: 7px;
      font-weight: 800;

      } 
  
    

    th {
      border:none;
      font-weight: 400;
      font-size: 22px; 
    }
    
  } 


  tr:nth-child(even) {
    background: linear-gradient(to left, #011434,#0743ab);
  } 

 

  tr td {
    font-size: 14px;
    transition: 800ms all ease;
    padding: 8px 10px !important;
  }

  tr td:hover {
    
    transition: 800ms all ease;
    
  }


  th:nth-child(1) { 
    white-space: nowrap;
    i{
      color:  #00ffbb;
      font-size: 15px;
    }
      
    text-align:center;
    } 


    th:nth-child(2) { 
      white-space: nowrap;
      i{
        color: #00ffbb;
        font-size: 15px;
      }
    text-align:center;
    } 

    
    th:nth-child(3) { 
      white-space: nowrap;

      i{
        color: #00ffbb;
        font-size: 15px;
      }
      
      text-align:center;
      } 

      th:nth-child(4) { 
        white-space: nowrap;
        i{
          color: #00ffbb;
          font-size: 15px;
        }
        
        text-align:right;
        } 

    th:nth-child(5) { 
      white-space: nowrap;
      i{
        color: #00ffbb;
        font-size: 15px;
      }
      
      text-align:right;
      }

      th:nth-child(6) { 
        white-space: nowrap;
        i{
          color: #00ffbb;
          font-size: 15px;
        }
      
        text-align:right;
        } 


        th:nth-child(7) { 
          white-space: nowrap;

          i{
            color: #00ffbb;
            font-size: 15px;
          }
      
          text-align:right;
          } 


          th:nth-child(8) { 
            white-space: nowrap;


            i{
              color: #00ffbb;
              font-size: 15px;
            }
      
            text-align:right;
            }

            th:nth-child(9) { 
              white-space: nowrap;
  
  
              i{
                color: #00ffbb;
                font-size: 15px;
              }
        
              text-align:center;
              }

  //// aqui empieza el cuerpo
    td:nth-child(1) { 
      
      text-align:left;
      text-transform: lowercase;
      } 
  
  
      td:nth-child(2) { 
      
      text-align:center;
      } 
  
      
      td:nth-child(3) { 
        
        text-align:center;
     
        } 
  
        td:nth-child(4) { 
          
          text-align:right;
          } 
  
      td:nth-child(5) { 
        
        text-align:right;
        } 

        td:nth-child(6) { 
        
          text-align:right;
          }
  
          td:nth-child(7) { 
        
            text-align:right;
            }
  
            td:nth-child(8) { 
      
              text-align:right;
              
              
                
              transition: all 500ms ease;
              cursor:pointer !important;
              
         
    
             
    
              } 
              td:nth-child(9) { 
      
                text-align:center;
                
                
                  
                transition: all 500ms ease;
                cursor:pointer !important;
                
           
      
               
      
                } 
    
    
              td:nth-child(9):hover { 
          
                text-align:center;
                
                button {
                  box-shadow: 0px 0px 7px rgba(230, 230, 230, 0.39) !important; 
                  background: #00b652;      
                  transition: all 500ms ease;
                } 
                
                    
               transition: all 500ms ease;
               
      
                }
        
  
  
  
}





.tabla_proyectos { 

  thead { 
    
    tr { 
    
      
      th:nth-child(1):hover::after { 
        background: linear-gradient( to bottom left,  #071535, #013152);
        box-shadow: 0px 0px 1px #01209b;
        width: 250px;
        height: 60px;
        padding:10px 18px 10px 18px;
        border-radius: 100px;
        position: absolute;
        font-size: 13px;
        content:"Aquí encontraras el Código unico de la oferta ";
        white-space: pre-wrap;
        text-align:left;
        top:-70px;
        font-weight: 400;
        left:0%;
        color:#fff;
        transition: all 50ms ease;
        text-transform: initial;
        border:1px solid #3969f7;

        } 
        th:nth-child(2):hover::after { 
          background: linear-gradient( to bottom left,  #071535, #013152);
          box-shadow: 0px 0px 1px #01209b;
          width: 250px;
          height: 60px;
          padding:10px 18px 10px 18px;
          border-radius: 100px;
          position: absolute;
        font-size: 13px;
          content:"país donde se encuentra la participación";
          white-space: pre-wrap;
        text-align:left;
        top:-70px;
        font-weight: 400;
        left:5%;
        color:#fff;
        transition: all 50ms ease;
        text-transform: initial;
        border:1px solid #3969f7;
  
          } 
          th:nth-child(3):hover::after { 
            background: linear-gradient( to bottom left,  #071535, #013152);
          box-shadow: 0px 0px 1px #01209b;
          width: 250px;
          height: 60px;
          padding:10px 18px 10px 18px;
          border-radius: 100px;
          position: absolute;
        font-size: 13px;
            content:"Representa el tipo de la participación";
            white-space: pre-wrap;
            text-align:left;
            top:-70px;
            font-weight: 400;
            left:8%;
            color:#fff;
            transition: all 50ms ease;
            text-transform: initial;
            border:1px solid #3969f7;
    
            } 
            th:nth-child(4):hover::after { 
              background: linear-gradient( to bottom left,  #071535, #013152);
              box-shadow: 0px 0px 10px #b5f1ff35;
              width: 250px;
              height: 60px;
              padding:10px 18px 10px 18px;
              border-radius: 100px;
              position: absolute;
              font-size: 13px;
              content:"Representa la Categoria de la participación";
              white-space: pre-wrap;
              text-align:left;
              top:-70px;
              font-weight: 400;
              left:14%;
              color:#fff;
              transition: all 50ms ease;
              text-transform: initial;
              border:1px solid #3969f7;
      
              } 
              th:nth-child(5):hover::after { 
                background: linear-gradient( to bottom left,  #071535, #013152);
                box-shadow: 0px 0px 1px #01209b;
                width: 250px;
                height: 60px;
                padding:10px 18px 10px 18px;
                border-radius: 100px;
                position: absolute;
                font-size: 13px;
                content:"el nombre del ofertante de la inversión";
                white-space: pre-wrap;
                  text-align:left;
                  top:-70px;
                  font-weight: 400;
                  left:20%;
                  color:#fff;
                  transition: all 50ms ease;
                  text-transform: initial;
                  border:1px solid #3969f7;
        
                } 
                th:nth-child(6):hover::after { 
                  background: linear-gradient( to bottom left,  #071535, #013152);
                  box-shadow: 0px 0px 1px #01209b;
                  width: 250px;
                  height: 60px;
                  padding:10px 18px 10px 18px;
                  border-radius: 100px;
                  position: absolute;
                  font-size: 13px;
                  content:"Aquí encontraras el nombre de la inversion o contrato";
                  white-space: pre-wrap;
                  text-align:left;
                  top:-70px;
                  font-weight: 400;
                  left:30%;
                  color:#fff;
                  transition: all 50ms ease;
                  text-transform: initial;
                  border:1px solid #3969f7;
          
                  } th:nth-child(7):hover::after { 
                    background: linear-gradient( to bottom left,  #071535, #013152);
                      box-shadow: 0px 0px 1px #01209b;
                      width: 250px;
                      height: 60px;
                      padding:10px 18px 10px 18px;
                      border-radius: 100px;
                      position: absolute;
                      font-size: 13px;
                    content:"Es la clasificación de riesgo de la inversión";
                    white-space: pre-wrap;
                    text-align:left;
                    top:-70px;
                    font-weight: 400;
                    left:45%;
                    color:#fff;
                    transition: all 50ms ease;
                    text-transform: initial;
                    border:1px solid #3969f7;
            
                    }  

                    th:nth-child(8):hover::after { 
                      background: linear-gradient( to bottom left,  #071535, #013152);
                      box-shadow: 0px 0px 1px #01209b;
                      width: 250px;
                      height: 60px;
                      padding:10px 18px 10px 18px;
                      border-radius: 100px;
                      position: absolute;
                      font-size: 13px;
                      content:"Si la participación está cubierta o garantizada";
                      white-space: pre-wrap;
                      text-align:left;
                      top:-70px;
                      font-weight: 400;
                      left:50%;
                      color:#fff;
                      transition: all 50ms ease;
                      text-transform: initial;
                      border:1px solid #3969f7;
              
                      } 
                      th:nth-child(9):hover::after { 
                        background: linear-gradient( to bottom left,  #071535, #013152);
                        box-shadow: 0px 0px 1px #01209b;
                        width: 250px;
                        height: 60px;
                        padding:10px 18px 10px 18px;
                        border-radius: 100px;
                        position: absolute;
                      font-size: 13px;
                        content:"Porcentaje de Interes en relación a 1 año";
                        white-space: pre-wrap;
                      text-align:left;
                      top:-70px;
                      font-weight: 400;
                      left:60%;
                      color:#fff;
                      transition: all 50ms ease;
                      text-transform: initial;
                      border:1px solid #3969f7;
                
                        } 
                        th:nth-child(10):hover::after { 
                          background: linear-gradient( to bottom left,  #071535, #013152);
                        box-shadow: 0px 0px 1px #01209b;
                        width: 250px;
                        height: 60px;
                        padding:10px 18px 10px 18px;
                        border-radius: 100px;
                        position: absolute;
                      font-size: 13px;
                          content:"Periodo de vencimiento disponibilidad de la inversión";
                          white-space: pre-wrap;
                          text-align:left;
                          top:-70px;
                          font-weight: 400;
                          left:65%;
                          color:#fff;
                          transition: all 50ms ease;
                          text-transform: initial;
                          border:1px solid #3969f7;
                  
                          } 
                          th:nth-child(11):hover::after { 
                            background: linear-gradient( to bottom left,  #071535, #013152);
                            box-shadow: 0px 0px 10px #b5f1ff35;
                            width: 250px;
                            height: 60px;
                            padding:10px 18px 10px 18px;
                            border-radius: 100px;
                            position: absolute;
                            font-size: 13px;
                            content:"Monto total del costo de la inversión completa";
                            white-space: pre-wrap;
                            text-align:left;
                            top:-70px;
                            font-weight: 400;
                            left:70%;
                            color:#fff;
                            transition: all 50ms ease;
                            text-transform: initial;
                            border:1px solid #3969f7;
                    
                            } 
                            th:nth-child(12):hover::after { 
                              background: linear-gradient( to bottom left,  #071535, #013152);
                              box-shadow: 0px 0px 1px #01209b;
                              width: 250px;
                              height: 60px;
                              padding:10px 18px 10px 18px;
                              border-radius: 100px;
                              position: absolute;
                              font-size: 13px;
                              content:"Porcentaje de interes de la ganancia";
                              white-space: pre-wrap;
                                text-align:left;
                                top:-70px;
                                font-weight: 400;
                                left:75%;
                                color:#fff;
                                transition: all 50ms ease;
                                text-transform: initial;
                                border:1px solid #3969f7;
                      
                              } 
                              th:nth-child(13):hover::after { 
                                background: linear-gradient( to bottom left,  #071535, #013152);
                                box-shadow: 0px 0px 1px #01209b;
                                width: 250px;
                                height: 60px;
                                padding:10px 18px 10px 18px;
                                border-radius: 100px;
                                position: absolute;
                                font-size: 13px;
                                content:"Monto disponible para invertir ";
                                white-space: pre-wrap;
                                text-align:left;
                                top:-70px;
                                font-weight: 400;
                                left:80%;
                                color:#fff;
                                transition: all 50ms ease;
                                text-transform: initial;
                                border:1px solid #3969f7;
                        
                                } th:nth-child(14):hover::after { 
                                  background: linear-gradient( to bottom left,  #071535, #013152);
                                    box-shadow: 0px 0px 1px #01209b;
                                    width: 250px;
                                    height: 60px;
                                    padding:10px 18px 10px 18px;
                                    border-radius: 100px;
                                    position: absolute;
                                    font-size: 13px;
                                  content:"Número de participantes o personas dentro de la inversión";
                                  white-space: pre-wrap;
                                  text-align:left;
                                  top:-70px;
                                  font-weight: 400;
                                  left:85%;
                                  color:#fff;
                                  transition: all 50ms ease;
                                  text-transform: initial;
                                  border:1px solid #3969f7;
                          
                                  } 
                                  
                    
    
    }
  }


  
  
  td:nth-child(1) { 
    
    text-align:left;
    } 


    td:nth-child(2) { 
    
    text-align:center;
    } 

    
    td:nth-child(3) { 
      
      text-align:left;
      } 

      td:nth-child(4) { 
        
        text-align:left;
        } 

    td:nth-child(5) { 
      
      text-align:left;
      }

      
    td:nth-child(6) { 
      
      text-align:left;
      }
      
    td:nth-child(7) { 
      
      text-align:left;
      }
      
    td:nth-child(8) { 
      
      text-align:left;
      }

      td:nth-child(9) { 
        
        text-align:right;
        } 

        td:nth-child(10) { 
          
          text-align:left;
          }


          td:nth-child(11) { 
          
            text-align:right;
            } 


            td:nth-child(12) { 
              
              text-align:right;
              } 
      
              
              
            td:nth-child(13 ) { 
              
              text-align:right;
              } 

              td:nth-child(14 ) { 
              
                text-align:right;
                } 
}

 

////////////////


.mercado-primario-bg {
  background: linear-gradient(to bottom left, rgba(0, 0, 0, 0.953), rgba(5, 33, 58, 0.596), rgba(10, 54, 112, 0.891)), url(./bg-dark.jpg);
      background-repeat: repeat, repeat;
      background-size: auto, auto;

  
  display: flex;
  align-items: left;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  



  @media screen and(min-width:0px) and (max-width:500px) {
      
      min-height: 100px;
      
    }

} 


.main-panther-bg{ 
  background: linear-gradient(to bottom left, rgba(0, 0, 0, 0.953), rgba(5, 33, 58, 0.596), rgba(10, 54, 112, 0.891)), url(./bg-dark.jpg);

      

  
  
  
  
  
background-size: 100% !important;
background-repeat: repeat !important;


  @media screen and(min-width:0px) and (max-width:500px) {
      height: 100%;
      min-height: 800px;
      
    }

}  

.main-panther-bg-demo::after{
  background:linear-gradient(to bottom , #493e19c9, #3c332095,#13100ba4), url(./Panther-pattern-7.png) !important;
  filter: blur(-31px) !important;
  content:'';
  height:100%;
  width:100%;
  position: absolute;
  top:0;
  left:0;
  z-index: -1;
}

.main-panther-bg-demo {
  // background:linear-gradient(to bottom left, #170f06, #172f4dcc,#018e7b), url(./Panther-pattern-7.png) !important;
  background: none !important;
  background-repeat: repeat !important;
  background-size: 100% !important;
  position: relative;
  


  @media screen and(min-width:0px) and (max-width:500px) {
      height: 100%;
      min-height: 800px;
      
    }


}


/// vamos a definir los estilos en el modal

.width-100 {
  width: 100%;
} 

.distribucion-contrato-footer {
 background: linear-gradient(to right, #0f4580, #046684)  !important;
 padding: 40px 20px 20px 20px !important;
 border-top: 1px solid #fff !important;
} 

.distribucion-contrato-footer-title{
  font-size: 35px !important;
} 

.bg-modal-invertir {
  
  background:linear-gradient(to bottom left, #080d18b1, #010309d8), url(./bg-panama.jpg);
  background-position: 40% 49%;
  background-size:100%;
  background-repeat:no-repeat; 
  transition: all 500ms ease;
  border-radius: 0px !important;
}   



.bg-modal-invertir-send {
  
  background:linear-gradient(to bottom left, #145dff, #406ae7d9), url(./bg-panama.jpg);
  background-position: 40% 49%;
  background-size:100%;
  background-repeat:no-repeat; 
  transition: all 500ms ease;
  border-radius: 0px !important;
}  



.bg-modal-retiro-tittle {
  background:linear-gradient(to bottom left, rgba(0, 135, 255, 0.68), rgb(26, 39, 232));
  padding: 20px 0px 20px 0px !important;
  border-radius: 300px !important;
} 


.bg-modal-retiro {
  
  

    text-shadow: 0px 0px 14px rgb(10, 57, 158);
    font-size: 2.4rem;
    text-align: center;
    border-bottom: 1px solid #005eff;
    padding: 20px 40px 20px 40px;
    background: linear-gradient(to bottom,  #000010e9 , rgba(1, 1, 21, 0.729) ), url(./bg-panama.jpg) !important;
    background-position: 50% 60%;
    background-size:100%;
    background-repeat:no-repeat;
  
  
}

.btn-invertir {
  margin: 0px !important;
  padding: 1rem !important;
  
  font-weight: 300 !important;
  
  background: #fff !important; 
  transition: all 500ms ease;
  color: #051746;
  border: 1px solid #ffffff !important;
  font-size: 22px;
  
  transition: 500ms all ease;


  
  
  font-weight: 300 !important;
   
  
  @media screen and(min-width:0px) and (max-width:500px) {
      
    padding: 0.8rem !important;
    font-size: 28px;
    
  }

 

} 

.font-light-weight-modal {
  font-weight: 300;
} 

.modal-power-up {
  background: linear-gradient(to left, #000, #000);
}

.modal-power-up-profile {
  background: linear-gradient(to left, #2538a5, #0f2f6c) !important;
  border-radius: 20px !important;
  border: 1px solid #0070ff !important;
  z-index: 0;
} 

.modal-power-up-profile-indexless {
  
  z-index: -1;
}

 

.btn-invertir:hover {
  background: #fff !important;
  color: #636363;

  
  
  transition: all 500ms ease;
  

  @media screen and(min-width:0px) and (max-width:500px) {
      
    padding: 0.8rem !important;
    font-size: 28px;
    
  } 

  
} 

.btn-invertir:focus {
  margin: 0px !important;
  
  
  border: 1px solid #ffffff !important;
  
  transition: all 500ms ease;
  
  @media screen and(min-width:0px) and (max-width:500px) {
      
    padding: 0.8rem !important;
    font-size: 28px;
    
  }
} 


.chart-container{ 
  background:#13113c;
  height: 300px;
  width: 100%;
  justify-content: center;
  display: flex; 
  
  border-radius: 20px; 
  box-shadow: 0px 0px 24px $info;

}



.Modal-inversion {
  width: 100%;
  
}  


.modal-botones-fix {
  width: 100% !important;
}


.app-panther-bar-dos {
  @media screen and(min-width:0px) and (max-width:500px) {
      
    width: 200px !important;
    
    
  }
}


.Modal-inversion-Profile {
  width: 80%;

  @media screen and(min-width:0px) and (max-width:500px) {
      
    width: 100% !important;
    
    
  }


  
} 

.Modal-Footer-style {
  background:#00043e !important;
  border: 1px solid #fff3 !important;
  padding: 10px 70px 10px 70px !important;
} 


// vamos con el input de la api 

.input-invertir {
  -webkit-appearance: initial !important;
  appearance: initial !important;
  background: #041e6dd4!important;
  border-bottom: 1px solid #e6ad00;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 3px;
  transition: all 500ms ease;
  font-size:30px;
  margin: 10px 0px 10px 0px !important;
  color: #fff;
  // animation: lucescool 1500ms linear 500ms infinite alternate;
}  


.input-invertir:hover {
  background:  rgb(0, 123, 255)!important;
  transition: all 500ms ease
}


@keyframes  lucescool {

  0%{
    box-shadow: 0px 0px 5px rgb(3, 6, 58);
  }

  100%{
    box-shadow:0px 0px 205px   rgb(0, 123, 255);
  }

}



.mensaje-inversion {
  background: #000a30;
  width: 100%;
  display: block;
  padding: 10px 0px 50px 0px !important;
  transition: all 500ms ease;
  font-weight: 300;
  font-size: 33px;
  margin:0px !important; 
  color: #2ae69e;
  
  p {
    margin: 0px !important;
  }

} 


.invertir-fx {
  transition: all 500ms ease !important;
} 



//tr tabla row

.bg-panther {
  padding: 30px;
  
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  
  
} 

.bg-panther-td {
  

  height: 160px;
  vertical-align: center; 
  border: 1px solid #2c25ff;
  white-space: wrap;
  overflow:hidden;
  

  @media (max-width:500px) {

    height: 200px;
    

    p { 
      padding:10px ;
      font-size: 8px !important;
    }

  }
  


  
} 

.bg-panther-td p {
 
  font-size: 12px;
  
}  

.fix-center-imagen-panther {
  align-items: center;
  display: flex;
}


.fix-mov-title {
  font-size: 17px;
  color: rgb(169, 169, 169);
  padding-bottom: 3px ;
  padding-left: 20px;
} 


.card-tasks .table-full-width {
  max-height: 650px !important;
  position: relative;
} 

.card-tasks  {
  height: 435px;
  position: relative;
} 

.card-title-fix{
  margin-bottom: 1px !important;
  font-size: 30px !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.323) !important; 
} 

///DEPOSITO
/// vamos a usar estilos personalizados para el deposito


.deposito-col-box {
  align-self: center;
} 

.input-deposito-moneda {
  border: 1px solid #3969f7 !important;
  border-right: 1px solid #3969f7 !important;
  padding-right: 4px !important;
}

.deposito-form-control-props-input {
  height: calc(1.9em + .75rem + 2px);
  font-size:14px;
  border-radius: 0px !important;
}  


.form-control-deposito-container {
  margin:0px !important;
  
  span {
    background-color:none !important;
  }
} 


.form-control-deposito-container .form-control {
  margin:0px !important;
  
  
} 

.input-text-deposito-group{
  background: none !important;
  border-radius: 0px;
} 

.input-group-append-deposito {
  border: none !important;
} 

.custom-select-deposito{
  cursor:pointer;
  padding: 3px !important ;
  height: calc(2.5em + .75rem + 2px);
  color: #fff;
  border-radius: 0px 3px 3px 0px !important;
  
  background:#0a0c0e4a !important
  
} 

.acc-deposito-container {
  h5 { 
    line-height: 14px;
    margin: 0px !important;

  }

  h1 { 
   
    margin-bottom: 0px !important;

  }
} 

.pointer-cursor {
  cursor:pointer;
} 

.font-fix-deposito-14 {
  font-size: 14px !important;
} 


.deposito-label-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
} 

.deposito-text-refer {
  background:#0a0c0e4a ;
  border-radius: 100px;
  padding: 4px 10px 4px 50px;
} 


.scrolldown-icon {
  padding: 0px 10px 0px 10px;
  width: 100% !important;
  height: 100px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
  background: #0b2e65;
  border-radius: 100px;
  box-shadow: 0px 0px 14px #306eff79;
  border:1px solid #0047ff; 
  margin:20px 0px 20px 0px;

  h5 {
    margin: 0px !important;
    text-align:right;
    font-size: 17px;

    span {
      font-weight: 900;
    }

  }

}

/// estos estilos son los que tocan el tap
/// aqui colocare un media query para hacer esto responsive

.box-perfil-center-fix {
  align-items: center;
  justify-content: center;
  display: flex;

  @media screen and(min-width:0px) and (max-width:500px) {
    padding: 10px 0px 10px 0px !important;
    
  }
  
} 


.app-panther-bar {
  background:linear-gradient(to bottom right, #002f68, #001631)  !important;
  border-radius: 3px;
  
  
} 


//// aqui vamos a poner los estilos de la barra, de mercado secundario y primario


.app-panther-bar-dos {
  background:#0b2b83  !important;
  border-radius: 3px;
  transition: all 1000ms ease;
  border-radius: 40px;
  box-shadow: 0px 0px 17px #000;
} 

.app-panther-bar-dos:hover {
  background: #005eff !important;
  transition: all 500ms ease;
  transform: scale(0.97);
  box-shadow: 0px 0px 17px #000;
} 

.appbar-box-panther-dos {
  transition: all 1000ms ease;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $primary  !important;
}

.MuiTab-textColorPrimary {
  color: #fff !important;
} 

.MuiBox-root-46 {
  padding: 24px 0px 24px 0px !important;
} 

.MuiBox-root-59 {
  padding: 24px 0px 24px 0px !important;
} 





select {
  background: rgb(7, 32, 135) !important;
  border-radius: 100px !important;
  cursor: pointer !important;
  color: #fff !important;
  
} 


 option {
  background: rgb(6, 25, 55) !important;
   border-radius: 100px !important;
  padding: 40px !important;
  color: #fff !important;
  cursor: pointer !important;
 
  font-size: 15px;
  
   
  
 } 


 option:hover {
  background: rgb(28, 8, 99) !important;
  cursor: pointer !important;
  color: #fff !important;
} 


select:focus {
  background: linear-gradient(to left,#008498,#1449bc)!important;
} 

option:focus {
  background: rgb(28, 8, 99) !important;
  cursor: pointer !important;
  color: #fff !important;
} 

.option-fix {
  background: #00043e;
  font-size: 20px;
  cursor: pointer !important;
} 

.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
} 

.select-items {
  position: absolute;
  background-color: Blue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}



.modalfooterhelp {
  overflow-x: scroll;
} 


.panther-retiro-label {

  margin-top: 5px !important ;
  font-size: 14px !important;
  font-weight: 600 !important;

} 


.terminos-fix-scroll {
  overflow-y: scroll;
}

.static-height {
  min-height: 100px !important;
  overflow: hidden !important;
  scroll-behavior: smooth !important;
}

/// esto es el boton del login y de registrarse

.btn-panther-power {
  padding: 20px 0px 20px 0px !important;
  border: none !important; 

  @media (max-width: 500px) {
    padding: 10px 0px 10px 0px !important;
    
    
  }
} 

.btn-panther-power:hover {
  background: rgb(0, 94, 255) !important;
}

.btn-panther-power:focus {
  background:rgb(48, 124, 255) !important;
}  


//// aqui vamos a colocar la version lite

.btn-panther-power-sm {
    padding: 10px 9px 10px 9px !important;
    border: none !important;
}


.btn-panther-power-sm:hover {
  background: rgb(0, 94, 255) !important;
}

.btn-panther-power-sm:focus {
  background: #3c183e !important;
}  



.btn-panther-power-sm-green {
  padding: 10px 9px 10px 9px !important;
  border: 1px solid rgb(42, 230, 158) !important;
  background: rgb(0, 130, 63) !important;
}


.btn-panther-power-sm-green:hover {
  border: 1px solid rgb(42, 230, 158) !important;
background: rgb(42, 230, 158)!important;
}

.btn-panther-power-sm-green:focus {
background: rgb(0, 94, 255) !important;
border: 1px solid rgb(0, 94, 255) !important;
} 





.btn-radius-panther-dos {
  border-radius: 100px;
  box-shadow: 0px 5px 30px #030e0e4b;
}



.checkbox-fix{
    font-size: 8px !important;
    color: #06f772 !important;
    background: #131e42  !important;
    box-shadow: 0px 0px 10px #000;
    margin:15px !important;
    transition: all 500ms ease-in-out !important;
}

.checkbox-fix:hover{
  
  color: #0460ff  !important;
  background: #010446 !important;
  box-shadow: 0px 0px 20px #000;
  margin:15px ;
  transition: all 500ms ease-in-out !important;
}



/// aqui vamos a poner los estilos del account card
.account-card {
  border: 1px solid #7c8086;
  background: #6f6f6fb8;
  transition: all 500ms ease ;
  cursor: pointer;




  }

  


  .account-card-overlay {
    position: absolute;
    background:linear-gradient(to top left, #000935, #021942f4);
    border: 1px solid #00abff ;
    
    
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -ms-flexbox;
    display: flex;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.account-card:hover .account-card-overlay {
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
} 


.add-tarjeta {
  flex-direction: column;
  display: flex;
  text-align: center;
  align-items: center;
  height: 100%;
} 

.add-account-card  {
  background: #011a3c;
  cursor: pointer !important;
  border: 1px solid #1260ca;
  transition: all 300ms linear;
}

.add-account-card:hover {
  background: #041b66;
  transition: all 300ms linear;
} 


.account-bank {
  flex-wrap: nowrap;
  display: flex; 
  background: linear-gradient(to right top, #102448f5,#0638acec ), url(./bg-panama.jpg);
  background-position: 50% 70%;
  background-size:cover ;
  background-repeat:no-repeat;
  // background: linear-gradient(to right top, #3574dd,#0637ac ) !important;
  
} 

.account-bank-icon {
  font-size: 40px !important;
  
}


.tarjeta-modal-fix {
  max-height: 500px;
  overflow-y:scroll;
  padding: 0px 30px 0px 30px;
  margin: 10px 0px 10px 0px;
  background: #010e24;
  

} 


.terminos-modal-fix { 
  max-height: 500px;
  overflow-y:scroll;
  padding: 0px 30px 0px 30px;
  margin: 10px 0px 10px 0px;
  background: #010e24; 

  
  



  

}


/// aqui vamos a poner los estilos 
// de los titulos en el dashboard

.dash-h5 {
  font-size: 17px;
} 


/// aqui vamos a colocar un custom style del form, el color del radio

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #09194a !important; 
  cursor:pointer;
}

.custom-radio .custom-control-input ~ .custom-control-label {
  
  cursor:pointer ;
} 

.bg-radio-fix {
  justify-content: space-between;
  display: flex;
  background: #2c25ff;
  padding: 10px 0px 10px 14px;
  border-radius: 3px;
  transition: all 500ms linear;
} 

.bg-radio-fix:hover {
  justify-content: space-between;
  display: flex;
  background: #0066ff;
  padding: 10px 0px 10px 14px;
  border-radius: 3px;
  color:#2c25ff;
  transition: all 500ms linear;
} 



///agregar cuenta bancaria, modal form stylos
.panther-cuenta-bancaria {
  margin-top: 9px;
  font-size: 10px !important;
  font-weight: 600 !important;
} 


// aqui vamos a colocar los estilos para alinear el link de confirmacion
.verify-box {
  justify-content: center !important;
  align-content: center;
  min-height: 900px;
  
}

//// aqui estoy colocando lops componentes de dev

.ondev {

  color: #d0092d !important;
  text-shadow: 0px 0px 1px #ff002f;
  background: #2c25ff;
  font-size: 30px;
  text-align: center;
  margin:24px;
  border-radius: 4px;
  font-weight: 800;
  line-height: 26px;
  padding: 9px;
  

    .ondevspan {
      color:#b9c0d3;
      font-weight: 400;
      font-size: 12px;
    } 
    .ondevstrong {
      color: rgb(21, 178, 235);
    }
} 




/// aqui vamos a poner los estilos de una ves por todas
#root  {

  
  
  
  
  background:linear-gradient(to bottom left ,#0b796a), url(./Panther-pattern-7.png) !important;

        background-repeat: repeat, repeat;
        background-size: auto, auto;
    background-repeat: repeat,repeat;
    background-size: auto,auto;
    background-size: cover;
    background-repeat: no-repeat;

  
  
}


.beta-text {
  color: #fff;
  font-size:30px;
  text-align: center;
  background: #0047ff;
  border-radius: 10px;
  padding: 10px ;
  animation: background 800ms infinite alternate;
}


.beta-text::after {
  content: "version";
  color: #fff;
  font-size:10px;
  text-align: center;
  margin: 2px;
  border-bottom: 1px solid #fff;
} 


.beta-text::before {
  content: "Panther 7";
  color: #fff;
  font-size:10px;
  text-align: center;
  margin: 2px;
  border-bottom: 1px solid #fff;
} 


@keyframes background {
  0% {
     background: #172f39;
    }
 
   100% {
     background:#0047ff;
   }
 }
 



 //// icono para retirar el dinero

 .account-bank-icon-max {
  font-size: 100px !important;
} 

/// el modal fix de detalle de cuenta

.tarjeta-modal-fix-detalle {
  max-height: 500px;
  overflow-y: scroll;
  padding: 0px 0px 0px 0px;
  margin: 10px 0px 10px 0px;
  background: #07337d;
}


.MuiBackdrop-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: -1;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.112) !important;
  -webkit-tap-highlight-color: transparent;
} 


//// aqui vamos a colocar el estilo de la imagen en msi inversiones

.imagen-mis-inversiones-fix {
  display: flex;
  justify-content: left;
  flex-direction: row;
  

  @media screen and(min-width:0px) and (max-width:500px) {

    overflow:scroll;
      
    p {
      font-size: 14px;
    }
  }
  

}

.imagen-mis-inversiones-fix img {
  transition: all 500ms ease;  
    box-shadow: 0px 0px 10px rgb(20, 78, 223);
    height: 100px !important;
}

.imagen-mis-inversiones-fix img:hover {
  transition: all 500ms ease;   
  box-shadow: 0px 0px 100px rgb(8, 61, 196);
  cursor:zoom-in;
} 


.display-mis-inversiones {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 0px !important;
} 


//// aqui vamos a colocar el fix de la ventana modal

.imagen-mis-inversiones-fix-dos {
  display: flex;
  justify-content: left;
  flex-direction: row;
  height: auto !important;
  
}

.imagen-mis-inversiones-fix-dos img {
  
  margin-top:14px;
  border-radius: 100px;
  transition: all 500ms ease;  
  box-shadow: 0px 0px 100px rgb(8, 61, 196);
    height: 70px !important;
}

.imagen-mis-inversiones-fix-dos img:hover {
  transition: all 500ms ease;   
  box-shadow: 0px 0px 100px rgb(8, 61, 196);
  cursor:zoom-in;
  
} 

.imagen-mis-inversiones-fix-dos{
  padding-left: 30px;
  padding-right: 30px;
}






//////// aqui vamos a seguir colocando el fix del titulo del modal
.mis-inversiones-h5 {
  margin: 0px 0px 10px 0px  !important
} 


.resetpassword {
  font-size:13px !important;
  margin: 0px 0px 0px 0px !important;
} 




///// aqui vamos a colocar los estilos del filtro dinamico 
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #ffffff;
  height: 20px;
  width: 20px;
  
  border-radius:100px;
  transform:rotate(45deg);
  background:linear-gradient(to bottom right, #04dede, #00ffae);
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 10px #2e14da, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}




/* Special styling for WebKit/Blink */
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid #ffffff;
  height: 20px;
  width: 20px;
  
  border-radius:100px;
  transform:rotate(45deg);
  background:linear-gradient(to bottom right, #04dede, #00ffae);

  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 1px 1px 10px #2e14da, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 10px #2e14da, 0px 0px 1px #0d0d0d;
  border: none;
  border-radius:100px;
  height: 20px;
  width: 20px;
  
  transform:rotate(45deg);
  background:linear-gradient(to bottom right, #04dede, #00ff91);
  
  cursor: pointer;
  position: relative;
} 



///////// este bonton deesta danhado

// input[type=range]::-moz-range-thumb::before {
  
  
//   border-radius:100px;
//   height: 50px;
//   width: 50px;
//   content: "sssssssssssssssssssssss";
//   transform:rotate(45deg);
//   border:1px solid #0000;
//   display: grid;
//   cursor: pointer;
//   position: absolute;
//   z-index:99999 !important;
//   top:0;
//   right:0;
// } 


///////// este bonton deesta danhado


.grid-container-filtro-buscador-rango{
  border:1px solid rgba(0, 81, 255, 0.071);
  position: relative;
  border-radius: 3px;
  padding: 0px 0px 20px 0px;
}






/* All the same stuff for IE */
input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 10px #2e14da, 0px 0px 1px #0d0d0d;
  border: 1px solid #ffffff;
  border-radius:100px;
  height: 20px;
  width: 20px;
  
  transform:rotate(45deg);
  background:linear-gradient(to bottom right, #04dede, #00ff99);
  cursor: pointer;
} 


.text-filtro {
  font-size: 30px;
}


//// aqui vamnos a hacer el estilo de la paginacion 

.box-pagination {
  border-radius: 50px;
  height:50px;
  width: 50px;
  color: #fff;
  background: #00214d;
  border: 1px solid #04ff89;
  justify-content: center;
  display: flex;
  align-items:center;
  @media (max-width: 992px) {
    border: 1px solid #ff5c04 !important;
    
  }
} 
.box-pagination::after {
  content: "Página actual";
  height:100px;
  width: 100%;
  position: absolute;
  text-align: center;
  white-space: nowrap;
  
  @media (max-width: 992px) {
    
    justify-content:center;
    white-space: nowrap;
    left: -14px;
    
  }


}


.box-pagination-father {
  justify-content: center;
  display: flex;
  align-items: center;
  
} 


.height-fix-productos-inversion {
  
  display: flex;
  
} 


.card-full-task {
  height: 100% !important;
} 




.table-full-width-fix {
  max-height: 1060px !important;
  position: relative;
}
.table-export-container {
  background:linear-gradient( to right, #000, rgba(0, 0, 0, 0));
  border-radius: 100px 100px 1px 1px;
  text-align:center;
  div {
    .table-export-container-fix {
      align-items:center;
      display: flex;
      border:1px solid rgb(17, 83, 184);
      text-align: right !important;
      cursor:pointer;
      border-radius: 100px;
      background: #0b2b83;
      transition: all 300ms ease-in-out;
      margin: 0px 0px 0px 10px; 


      @media (max-width: 992px) {
        margin: 10px 2px 10px 2px; 
        
        
      }
    
      
    }
    .table-export-container-fix:hover {
      background: #005eff !important;
      transform:scale(1.01);
      transition: all 300ms ease-in-out;
      } 


      .table-export-container-fix-dos {
        align-items:center;
        display: flex;

      }
    
  } 

  @media (max-width: 992px) {
    background:none;
    
    
  }
}  

.export-style {
display: grid;
grid-template-columns: 60% 40%;
align-items: center;
border-top:1px solid rgb(77, 145, 255);

 button {
  border-style: none;
  background: #000266;
  transition:  all 140ms ;
 }

 button:hover {
  transition:  all 140ms ;
  background: #0066ff;
 }
}



.line-fix {
  display: inline-flex;
  align-items: center;
  
    width: 100%;
    justify-content: center;
}

//// aqui vamos a colocar los estilos correspondientes
// responsive de los ultimos mov


.card-responsive-bg {
  background: linear-gradient(to bottom, #00051fe7 , #000000 ), url(./bg-panama.jpg);
  background-size:cover;
  background-repeat:no-repeat;
  transition: all 500ms ease;
  min-height: 200px;
  width: 100%;
  
  


  p {
    font-size: 0.99rem;
    letter-spacing: 0.11rem;
    padding-left: 12px;
    margin-bottom: 1px;
    border-left: 1px solid rgba(4, 170, 247, 0.35);
    margin-top: 9px;
    padding-top: 0px;
    line-height: 14px;
    margin-left: 10px;
    font-weight: 300;
        color: #fff;
        text-shadow: 0px 0px 10px #000;
}


} 
 
.card-responsive-bg:hover{
  
  animation: bg-card-move 28800ms infinite alternate;
}

@keyframes  bg-card-move {

  0%{
    background-position: 0% 100%;
    background-size: 280%;

  }

  100%{
    background-position: 100% 50%;
    background-size: 320%;
    
  }

}



.card-responsive-bg:focus {
  background: linear-gradient(to bottom, #0229f969 33%, #000000d6 10%, rgba(10, 10, 10, 0.824) 10%), url(./bg-panama.jpg);
  transform: scale(1.02);
  box-shadow: 0px 0px 4px #000;
  transition: all 500ms ease;
  cursor:zoom-in;
}


.card-responsive-bg:hover {
  background: linear-gradient(to bottom, rgba(16, 11, 107, 0.898),rgb(0, 0, 0)), url(./bg-panama.jpg);
  transform: scale(1.02);
  box-shadow: 0px 0px 4px #000;
  transition: all 500ms ease;
  cursor:zoom-in;
}

.card-responsive-07 {
  border-bottom: 1px solid #ffffff0f;
  border-radius: 5px;
}


.card-responsive-01 {

 font-size: 10px; 

 strong {
  font-size: 18px; 
  font-weight: 700;
 }


}
 

.card-responsive-02 {

  font-size: 10px; 
 
  strong {
   font-size: 11px; 
   font-weight: 700;
  }
 
 
 }
 .card-responsive-03 {

  font-size: 10px; 
 
  strong {
   font-size: 18px; 
   font-weight: 700;
  }
 
 
 }
 .card-responsive-04 {

  font-size: 10px; 
 
  strong {
   font-size: 10px; 
   font-weight: 700;
  }
 
 
 }
 .card-responsive-05 {

  font-size: 10px; 
 
  strong {
   font-size: 10px; 
   font-weight: 700;
  }
 
 
 }

 // aqui vamos a coloar los estilos de la tabla de inversiones en responsive
 .card-responsive-07-inversiones {
  border-bottom: 1px solid #ffffff0f;
  border-radius: 5px;
}


.card-responsive-01-inversiones {

 font-size: 10px; 
 padding:6px;
 
 strong {
  font-size: 11px; 
  font-weight: 700;
 }


}
 

.card-responsive-02-inversiones {

  font-size: 10px; 
  padding:6px;
  
  strong {
   font-size: 11px; 
   font-weight: 700;
  }
 
 
 } 


 
 .card-responsive-03-inversiones {

  font-size: 10px; 
  
  padding:6px;
  border-radius: 2px;
  strong {
   font-size: 10px; 
   font-weight: 700;
  }
 
 
 }
 .card-responsive-04-inversiones {

  font-size: 10px; 
  padding:6px;
  
 
  strong {
   font-size: 10px; 
   font-weight: 700;
  }
 
 
 }
 .card-responsive-05-inversiones {

  font-size: 16px; 
  

 
  strong {
   font-size: 10px; 
   font-weight: 700;
  }
 
 
 }






 .bienvenido {
   font-size: 17px;
   font-weight: 300;
   text-align: right;
   line-height: 16px;
   border-right: 1px solid #0093ff;
   padding-right: 10px;

  strong{
    font-weight: 800;
  } 


  span {
    font-size: 10px;
    font-weight: 500;
    

  } 


  @media screen and(min-width:0px) and (max-width:992px) {
    color: #173db1;
    
  }

 }  

//// arriba del tap de retiros , en beneficiario,l vamos a colocar un tap
 .span-retiros { 

  margin-bottom: 10px !important;
  span {
    font-size: 18px;
    font-weight: 400;
  }
   
 } 


 .retiros-appbar {
   margin: 0px 10px 0px 10px;
 } 


 /// aqui vamos a crear la calse de agregar la cuenta para que se alinea verticalmente

 .col-add-vertical-fix {
  display: grid;
  grid-auto-rows: minmax(150px, auto);
  grid-template-columns:repeat(1,1fr);
  align-items: center;
  justify-content: center;
  
  background: linear-gradient(to bottom,  #121741b0 10%, rgba(2, 26, 79, 0.503) 50%), url(./Panther-pattern-7.png);
  background-position: 50% 60%;
  background-size:40%;
  background-repeat:repeat;
  transition: all 500ms ease;
  margin-bottom:20px;
  border: 1px solid #011b4f;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #000;
  

  div {
    display: flex;
    width: 50% ; 
    border-radius: 14px;
    justify-content: center;
    margin: 0 auto;
    transition: all 500ms ease; 

    div{
      border-radius: 0px;
      transition: all 500ms ease; 
    }


    @media screen and(min-width:0px) and (max-width:992px) {
      width: 100% ;  
    }
  }


} 


.col-add-vertical-fix:hover {
  box-shadow: 0px 0px 14px #000;
  border-bottom: 1px solid #e8cf90;
   transition: all 500ms ease; 

   div{
     margin-bottom: 10px;
     
     margin-top: 0px;
     transition: all 500ms ease; 

     div {
      transition: all 500ms ease; 
      border-bottom: 1px solid #e8cf90;
      border-radius: 0px;
     }
   }

  


  
} 





//// aqui vamos a colocar los estilos del boton en x 

.cancel-btn-panther {
  
  justify-content: center;
  text-align:center;
  width: 50px;
  height: 50px;
  align-items: center;
  display:flex;
  border-radius:50%;
  transition: all 500ms ease-in-out;
  cursor: pointer;
  align-items: center;

  i {
    font-size: 35px;
  } 

  @media screen and(min-width:0px) and (max-width:500px) {
    width: 35px;
    height: 35px;
    i {
      font-size: 25px;
    } 
  
  }


} 

.cancel-btn-panther:hover {
  
  justify-content: center;
  text-align:center;
  text-shadow: 0px 0px 15px #000;
  align-items: center;
  display:flex;
  border-radius:50%;
  transition: all 500ms ease-in-out;
  transform: rotate(180deg);
  
  i {
    font-size: 35px;
    transform: rotate(180deg); 

    
  }


  @media screen and(min-width:0px) and (max-width:500px) {
    
    i {
      font-size: 25px;
    } 
  
  }
} 


// aqui vamos a colocar el contenedor del boton x
.cancel-box-btn {
  justify-content: center;
  display: flex;
  flex-direction:row;
  background: linear-gradient(to bottom,  #00007ddb , rgba(9, 15, 194, 0.742) ), url(./bg-panama.jpg);
  background-position: 50% 60%;
  background-size:300%;
  background-repeat:no-repeat;
  width: 50px;
  height: 50px;
  
  transition: all 500ms ease-in-out;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.452); 

  @media screen and(min-width:0px) and (max-width:500px) {
    width: 35px;
    height: 35px;
 
  }


} 

.cancel-box-btn:hover {
  background:rgb(0, 64, 176);
  transform: scale(1.03);
  box-shadow: 0px 0px 30px #2200ffb3;
  transition: all 200ms ease-in-out;
}

// aqui vamos a colocar el fix del titulo del modal para colocarlo en el medio

.fix-cuentaspropias-title {
  display:flex;
  flex-direction: column;
  justify-content: center;
} 

/// vamos a hacer del modal el background

.bg-modal-header {
  background: #0a143c !important;
  transition: all 500ms ease;
}

.bg-modal-header:hover {
  background: #12246d !important;
  transition: all 500ms ease;

}

.pin {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 4px;
  display:inline-flex;
  margin-left: 10px;
} 


.panther-pin-box {
  background: linear-gradient( to left, #0066a5,#1439ff) ;
  border-radius: 5px;
  box-shadow: 0px 0px 4px #000;
  padding: 15px;
  transition: all 900ms ease;
}

.panther-pin-box:hover {
  transform: scale(1.02);
  transition: all 900ms ease;
  cursor:zoom-in; 

  .pin {
    font-size: 20px;
    margin-left: 10px;
    font-weight: 800;
    letter-spacing: 21px;
    display:inline-flex;
    transition: all 900ms ease;
  } 
} 

//// estilo del dashboard, mercado secundario

.dash-merc{
  font-size: 8px;
  font-weight: 800;
} 

/// estilo de registro 
.retiro-tittle {
  
  h3 {
    text-shadow: 0px 0px 14px rgb(10, 57, 158);
    font-size: 2.4rem;
    text-align: center;
    border-bottom: 1px solid #005eff;
    padding: 20px 40px 20px 40px;
    background: linear-gradient(to bottom,  #000010e9 , rgba(1, 1, 21, 0.729) ), url(./bg-panama.jpg);
    background-position: 50% 60%;
    background-size:100%;
    background-repeat:no-repeat;
  }
  
} 



//// aqui vamos a colocar la clase de css para la venta en porcentage

.venta-calculo-box {

    background: #000;
    height: 100%;
    align-items: center;
    margin: 0px;
    padding: 20px 0px 20px 0px; 

  

  div:nth-child(1) {
    font-size:19px;
    letter-spacing: 1px;
    line-height: 19px;
  }

  
  
  div:nth-child(2) {
    font-size:20px;
    text-align:right;
    p{
      font-weight: 800 !important;
    }
  }

}

///// aqui vamos a colocar la clase

.nav-active-panther {
  background:linear-gradient(to bottom left, #ffe091d9, #ffebb69c), url(./Panther-pattern-7.png);
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat; 
  transition: all 500ms ease;
  color: #fff;
  border-bottom: 1px solid #f7b765;
  box-shadow: 0px 0px 7px #000;
  margin:12px 0px 12px 0px !important;
  
  font-weight: 900;
  
  align-items: center !important;

  // background: linear-gradient(0deg, #080f2b 0%, #03276b 100%);
  i {
     color: #0c3780 !important;
    // animation: animation-s2 1000ms ease-in-out 0ms infinite alternate !important;
    border-radius: 4px;
    transition: all 500ms ease;
   }
  
   p {
    // color: #493513 !important;
    color:#0c3780 !important;
    font-weight: 400 !important;
   }
} 
 




@keyframes animation-s2 {

  0% {
    background:rgba(234, 207, 2, 0.27);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.413);
    transition: all 500ms ease;
    opacity: 1;
  } 

  100%{
    background:rgba(118, 106, 14, 0.27);
    color: #fff;
    transition: all 500ms ease;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.359);
    
    opacity: 1;
  }


}


.height-fix-saldos {
  h5 {
    font-weight: 300;
    font-size: 1.3rem;
    text-shadow: 0px 0px 10px #002556;
    margin:0px;
    color: rgb(145, 145, 145);
  }

  div {
    font-size: 25px;
    letter-spacing: 5px;
    text-shadow:0px 0px 1px #fff;
    font-weight: 700;
    color:rgb(240, 240, 240);
  }
}  


.box-depositos-seleccion {
  height: 850px;
  margin:0px;
  background:linear-gradient(to left bottom, #0c1028eb, #04183f), url(./Panther-pattern-7.png);
background-position: 40% 45%;
background-size:cover;
background-repeat:no-repeat;

transition: all 500ms ease;
color: #fff;
border:1px solid #8c783c;
box-shadow: 0px 0px 20px #141fbbb2;
  border-radius: 3px;


  background-repeat: no-repeat;
  animation: animationin 550ms ease-in-out 0ms 1 normal; 

  padding: 46px;
  

  i {
    animation: bg-tooltips 2000ms ease-in-out 0ms infinite alternate;
    border:1px dashed rgb(0, 123, 255);
    box-shadow: 0px 0px 12px #000;
    transition: all 500ms ease;
  }


  i:hover {
    margin-bottom: 13px !important;
    transition: all 500ms ease;
  }
  

  @media screen and(min-width:0px) and (max-width:500px) {
    height: 650px;
    margin:0px;
    padding: 10px;

    h2 {
      font-size: 40px !important;
    }

    h3 {
      font-size: 19px !important;
      padding-left: 10px;
      padding-right: 10px;
      
      
    }

    p {
      font-size: 15px !important;
    }
  
  
  } 



}   


.box-depositos-seleccion-alerta {
  height: 470px;
  background:linear-gradient(to bottom left, #031a53, #1c326de9), url(./Panther-pattern-7.png);
background-position: 50% 45%;
background-size:100%;
background-repeat:repeat !important; 
width:100%;
transition: all 500ms ease;
color: #fff;
border: 1px solid #1367e5;
box-shadow: 0px 0px 20px #141fbbb2;
  border-radius: 4px;

  background-repeat: no-repeat;
  animation: animationin 550ms ease-in-out 0ms 1 normal; 
  margin: 0 !important;
  padding: 46px;
  
  
}   




@keyframes animationin {

  from{ 
      margin-top: 10px;
      opacity: 0;
      background-size:20%;

  }

to{
  margin-top: 0px;    
  opacity: 1;
  background-size:100%;
      
  }


} 


.proyectos-card-seleccion {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 31px;
  padding: 20px;
  border: 1px solid #1367e5;
  box-shadow: 0px 0px 20px #141fbbb2;
  


  h4 {
      margin-bottom: 7px;
      margin-top: 7px;
      border-bottom: 1px dashed rgb(189, 189, 189);
      font-size: 20px;
      font-weight: 900;
      color:#000266;
      
      small{
          font-size: 20px;
          text-align:center;
          justify-content: center;
      }
  }

  h5 {
      background: #000266;
      padding: 7px 15px 7px 15px;
      color:#fff;
      border-radius: 100px;
      cursor:pointer !important;
  
  } 

} 
 

.panther-open-titulo {
  
  justify-content: flex-end !important;
  align-items: center;
  display:flex;
  flex-direction: column; 

  i {
    font-size: 35px;
    color:#fff;
    border-radius: 100px;
    padding: 30px;
    background: #045de8;
}



  h2 {
    font-size: 70px;
    margin-bottom: 10px;
  } 

  
  h3 {
    font-size: 20px;
    text-align:center;
    margin-bottom: 1px;
  }

}  



.panther-open-titulo-alerta {
  
  justify-content: center !important;
  align-items: center;
  display:flex;
  flex-direction: column; 

  i {
    font-size: 35px;
    color:#fff;
    border-radius: 100px;
    padding: 30px;
    background: #045de8;
}



  h2 {
    font-size: 45px;
    margin-bottom: 1px !important;
    text-align: center;
    line-height: 45px;
  } 

  
  h3 {
    font-size: 20px;
    text-align:center;
    margin-bottom: 1px;
  }

} 



.border-panther {
  padding-bottom: 25px;
  border-bottom:1px solid rgb(17, 53, 133);
  margin-bottom: 25px;
} 


.d-none {
  display: none !important;
} 


.opacity-block {
  opacity: 0;
}

.opacity-done {
  opacity: 1;
} 


.listado-text-tittle {
  font-weight: 600;
  font-size: 19px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  justify-content: center !important;
  height: 40px !important;
  align-items: center;
  color: rgb(55, 242, 161);
  margin: 0px !important;
  padding: 30px 0px 30px 0px;
}




.app-dos {
  
  background: #005eff !important;
  border-radius: 3px;
  transition: all 1000ms ease;
  border-radius: 40px;
  box-shadow: 0px 0px 17px #000;
} 

.app-dos:hover {
  background:#0b2b83  !important;
  transition: all 500ms ease;
  transform: scale(0.97);
  box-shadow: 0px 0px 17px #000;
}  


.title-bold-green-help{
  font-weight: 500 !important;
  text-shadow:0px 0px 4px rgba(0, 0, 0, 0.256);
  color: rgb(234, 177, 8) !important;
  background: rgba(1, 70, 218, 0.16);
  border: 1px solid #2458a5;
  padding: 10px;
  border-radius: 100px; 
  position:relative;
  display:grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  transition: all 500ms ease;
  filter: saturate(10%);
  cursor:pointer;

  p {
    margin: 0px;
  }

  h2 {
    margin: 0px;
  }

  i {
    position:absolute;
    height: 100%;
    width: 40px;
    right: 25px;
    font-size: 30px;
    align-items: center;
    display: grid;
    
  }

} 

.title-bold-green-help:hover{
  box-shadow: 0px 0px 24px #000;
  filter: saturate(100%);
}


.text-loader-center {
  display:flex !important;
  justify-content: center !important;
  align-items: center !important;
} 


.doughnut-style .labels {
  color: #fff !important;

} 



.animate-raya-03 {
  position: relative;
  
  transition: all .5s ease;
  
  
  cursor: pointer;
  letter-spacing: 0;
  overflow: visible;
}

.animate-raya-03::after{
  content:"";
  bottom:-2px;
  left:13px;
  background: #2190f2;
  height:1px;
  width: 94%;
  z-index:0;
  position:absolute;
  transition: all .5s ease;
  animation: animationin3 1.15s ease-in-out 0ms 1 normal;
  
  
} 


@keyframes animationin3 {
  0% {
      opacity:0;
      width: 0px;
      
  }
  100% {
      width: 94%;
      opacity:1;
  }

} 
 
///////////////////
.animation-icon-in {
  animation: title-in 0.30s ease-in-out 0ms 1 normal;
} 

@keyframes title-in {
  0% {
      opacity:0;
      transform: scale(400%);
      
  }
  100% {
      
      opacity:1;
      transform: scale(100%);
  }

} 
 
 //////////////////////

.animation-icon-in-dos {
  animation: title-in-dos 0.60s ease-in-out 0ms 1 normal;
}


@keyframes title-in-dos {
  0% {
      letter-spacing: 4px;
      opacity:0;
      
      
  }
  100% {
    letter-spacing: 0px;
      opacity:1;
      
  }

} 
 /////////////////////////////////




 .animation-icon-in-cuatro {
  animation: title-in-cuatro 0.60s ease-in-out 0ms 1 normal;
 } 

 @keyframes title-in-cuatro {
  0% {
    padding-bottom:20px;
      opacity:0;
      
      
  }
  100% {
    padding-bottom:0px;
      opacity:1;
      
  }

}  



.animation-icon-in-cinco {
          animation: title-in-cinco 0.80s ease-in-out 2ms 1 normal;
        } 

        @keyframes title-in-cinco {
          0% {
            
              opacity:0;
              
              
          }
          100% {
            
              opacity:1;
              
          }

        }  


        .animation-icon-in-six {
          animation: title-in-six 0.40s ease-in-out 2ms 1 normal;
        } 

        @keyframes title-in-six {
          0% {
            
              border-radius: 28px;
              
              
          }
          100% {
            
            border-radius: 60px;
              
          }

        }  



        .atras-box {
          justify-content: right;
          display: grid;
          position: fixed;
          width: 100%;
          right: -100px; 
          z-index: 1;
          
          align-items: center;
          height: 40px;
          

          @media screen and(min-width:0px) and (max-width:992px) {
      
            position: relative;
            
          }
      }

        .atras-class {
          background: #040404;
          border-radius: 30px 0px 0px 30px; 
          border:1px solid rgba(255, 255, 255, 0.201);
          font-size: 25px;
          padding: 0px 150px 0px 30px ;
          width: 300px;
          cursor:pointer;
          
          transition: all 500ms ease;
          margin: 0 !important;
        }

        .atras-class:hover {
          background: #3969f7 !important;
          transition: all 500ms ease;
          background: #000;
          
        } 



        .paypal-help div {
          margin-bottom:10px;
          margin-top: 10px;

          padding-bottom:10px;
          padding-top: 10px;
          
          
        } 

        .bg-white {

          background: #fff !important;
        } 

        .text-black {
          color : #000 !important;
        } 


        .font-panther-fix-retiro {
          font-size: 17px;
        } 


        .tipo-fix-font { 
          font-size: 24px !important;
        } 

        .giro-text {
          font-size: 15px !important;
          font-weight: 600 !important;
        } 




        //// aqui estamos poniendo los estilos de productos de inversion
      
    .proyectos-fix-steper {
        overflow: hidden !important;
    } 



    .MobileSteper-Fix {
      margin-top: 20px;
      background: rgba(3, 3, 58, 0.229) !important;
      color: #fff !important;
      border: 1px solid rgb(0, 98, 218);
      border-radius: 100px;
      box-shadow: 0px 0px 10px rgba(17, 74, 117, 0.537) !important;
    }  


    .MobileSteper-Fix:hover{
      transition:all 500ms ease;
      box-shadow: 0px 0px 7px rgb(36, 36, 36) !important;
    } 



    .MobileSteper-Fix Button {
      color: #fff !important;
      outline: none !important;
    } 


    .MobileSteper-Fix Button :hover {
      color: rgb(23, 115, 254) !important;
      transition: all 500ms ease;
      outline: none !important;

       
    }  


    .proyectos-step {
      font-size: 30px;
      color: #fff;
      font-weight: 800;
      margin-bottom: 20px;
      letter-spacing: 2px;
      
    }

    .proyectos-step div {
      font-size: 10px;
      font-weight: 400;
      color: #fff;
    }

   
.panther-proyect-info {
  border: 1px solid #2c25ff;
  border-radius: 20px !important;
  margin-bottom: 15px;
  background: #090334;
} 




.text-help-proyectos { 
  background:linear-gradient(to bottom left, #020714ec, #0b2b83ee), url(./bg-panama.jpg);
  background-position: 40% 45%;
  background-size:100%;
  background-repeat:no-repeat;

  transition: all 500ms ease;
  color: #fff;
  border: 1px solid #1367e5;
  box-shadow: 0px 0px 20px #141fbbb2;
  border-radius: 30px;

  .hh2 {
    font-size:25px !important;
  }   
  

  font-size:14px;
  padding: 10px;
  


  strong {
    font-size:18px;
    font-weight: 900 !important;
    text-align: left !important;
  }
  

  .fix-interes {
    border:1px solid rgb(19, 108, 218);
    border-radius: 7px !important;
  } 


  .col-help {
    background: rgb(18, 63, 175) ;
    border-radius: 10px;
    margin-bottom: 12px !important;
    justify-content: center;
    display: flex;
    flex-direction: column;
  } 




 }

 
 .text-description {
  
  background: linear-gradient(to right bottom, #042676, rgba(7, 25, 95, 0.687));
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.42);
  margin: 0px 0px 6px 0px !important;
  border-radius: 0px;
  font-size: 23px;
  padding: 5px 3px 5px 13px;
  filter: saturate(80%) !important;
  border-bottom: 1px solid #ee8805;
}

.img-fix-productos {
  
  border: 1px solid #ee8805;
  filter: saturate(42%);
box-shadow: 0px 0px 0px #0e3bc2;
  
} 



.img-fix-productos-box::before { 

  content:"";
  position: absolute;
    background: linear-gradient( to bottom left, rgba(28, 134, 214, 0.975) , rgb(26, 72, 254));
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    z-index: 99999;
    opacity: 0.10;
    

}


.iconhelp-productos {
  

  i {
    font-size:20px ;
    margin-top: 10px;
    font-weight: 900;
    color: rgba(20, 96, 189, 0.961);
    
  }
} 


.animation-lead {
  animation: bg-tooltips 1500ms ease-in-out 0ms infinite alternate;
} 


.alerta-texto-profile {
  color: #e79801  !important;
  transition: all 500ms ease;
} 


.alerta-texto-profile-green {
  color: rgb(15, 218, 137) !important;
  transition: all 500ms ease;
} 

.margin-act  {
  margin-bottom: 1.7rem !important;
  
}


.placeholder-complete::placeholder {
  color: rgb(15, 218, 137) !important;
} 

.alerta-texto-profile-blu {
  color : rgb(36, 86, 237) !important;
  font-size: 12px;
  font-weight: 500;
  
} 

.seudo-hermano {
  position: absolute;
  top:-30px;
  right:0;
  height:20px;
  width:100%;
  justify-content: flex-end;
  z-index:0;
} 

.text-fix-btn{
  height: 100% !important;
    width: 100% !important;
  i {
    font-size: 18px !important;
    
  }
} 




.profile-fix-input {
  label {
    border-radius: 100px;
    padding-left:10px;
    padding-right:10px;
    border:1px solid #0031a3;
    margin-bottom:10px;
  }


  p {
    border: 1px solid #0031a3 !important;
    background: #0031a330 !important;
    border-radius: 100px;
    padding:10px;
    animation: animationin 550ms ease-in-out 0ms 1 normal; 
    box-shadow:0px 0px 4px #000 !important;
  }
} 





.Modal-Fix-Panther-Dos {

    display: flex !important;
    align-items:center !important;
    justify-content: center !important;

  
} 


.help-scroll {
  align-items: flex-start;
  

  @media screen and(min-width:0px) and (max-width:500px) {
      
    height:800px !important;
    
    overflow-y: scroll;
    
    
    
    .card {
      padding: 0px !important;
      margin-top: 200px !important;
      padding-bottom: 80px !important;
      
    }
    
    
  }
} 


.monto-dolares-alerta {
  margin: 0px  !important;
  padding: 0px !important;
  font-size: 13px !important;
} 


.input-monto-modal {
  font-size: 30px !important;
  border-bottom:1px solid #3969f7;
  border-top:0px solid #3969f7;
  border-right:0px solid #3969f7;
  border-left:0px solid #3969f7;
  background: #1a3a985e;
  color: #ffff;
  text-align: center;
  margin-top: 2px;
  transition: all 100ms ease;
  width:90%;
  
  margin-top:10px ;

  @media screen and(min-width:0px) and (max-width:500px) {
      
    width:100%;
    
    
    
  }



} 


.input-monto-modal:hover {


  box-shadow: 0px 0px 10px #000266;
  transition: all 400ms ease;
}

.input-monto-modal:focus {

  border-bottom:1px solid #ffa700;
  box-shadow: 0px 0px 10px #000266;
  transition: all 400ms ease;
} 


.text-fix-retiros-modal {
  font-size: 14px;
  margin-bottom:0px;
} 


.card-body-fix {
  padding:0px 0px 0px 0px !important;
} 


.number-retiro-span {
  font-size:19px !important;
  font-weight: 900 !important; 
  color: #ffff !important;
} 




.seudo-hermano-monto {
  position: absolute;
  top:0px;
  left:0px;
  height:0px;
  justify-content: center;
  background: #000;
}  


.monto-dolares {
  
  width: 100%;
  justify-content:center;
  display:flex;
  align-items:center;
  margin-top: 9px ;
  
}

.monto-dolares::before {
  content:"$";
  display:flex;
  justify-content:center;
  align-items:center;
  color: white ;
  width: 60px;
  height: 60px;
  z-index: 999999;
  top: -32px;
  left: 0px ;
  background: #005eff;
  position:absolute;
  border-radius: 100px; 
  box-shadow:0px 0px 9px #00000059;
  animation: animationin 1.15s ease-in-out 0ms 1 normal;
  
   

   @media screen and(min-width:0px) and (max-width:500px) {
      
    left: -30px !important;
    
    
    
  }


}  



.monto-dolares-tres {
  
  width: 100%;
  justify-content:center;
  display:flex;
  align-items:center;
  
  
}

.monto-dolares-tres::before {
  content:"$";
  display:flex;
  justify-content:center;
  align-items:center;
  color: white ;
  width: 39px;
  height: 39px;
  z-index: 999999;
  font-size: 20px;
  top: -21px;
  left: 20px ;
  background: #56492f85;
  position:absolute;
  border-radius: 100px; 
  box-shadow:0px 0px 9px #00000059;
  border: 1px solid rgb(124, 107, 49);
  animation: animationin 0.65s ease-in-out 0ms 1 normal;
  
   

   @media screen and(min-width:0px) and (max-width:500px) {
      
    left: 20px !important;
    
    
    
    
  }


}  



.monto-dolares-dos {
  
  width: 100%;
  justify-content:center;
  display:flex;
  align-items:center;
  
  
}

.monto-dolares-dos::before {
  content:"$";
  display:flex;
  justify-content:center;
  align-items:center;
  color: white ;
  width: 39px;
  height: 39px;
  z-index: 999999;
  font-size: 20px;
  top: -21px;
  right: 20px ;
  background: #005eff;
  position:absolute;
  border-radius: 100px; 
  box-shadow:0px 0px 9px #00000059;
  animation: animationin 0.65s ease-in-out 0ms 1 normal;
  
   

   @media screen and(min-width:0px) and (max-width:500px) {
      
    right: 20px !important;
    
    
    
    
  }


}  


.animate-raya-04 {
  position: relative;
  
  transition: all .5s ease;
  
  
  cursor: pointer;
  letter-spacing: 0;
  overflow: visible;
}

.animate-raya-04::after{
  content:"";
  top:-2px;
  left:0;
  background: #2cff92;
  height:1px;
  width: 100%;
  z-index:9999;
  position:absolute;
  transition: all .5s ease;
  animation: animation-raya 1.15s ease-in-out 0ms 1 normal;
  

} 

  
  
  



@keyframes animation-raya-down {
  0% {
      opacity:0;
      width: 0;
      
  }
  100% {
      width: 100%;
      opacity:1;
  }

} 


.animate-raya-05 {
  position: relative;
  
  transition: all .5s ease;
  
  
  cursor: pointer;
  letter-spacing: 0;
  overflow: visible;
  

}

.animate-raya-05::after{
  content:"";
  bottom:-10px;
  left:0;
  background: linear-gradient(to left, #2538a5, #0f2f6c) !important;
  height:6px;
  width: 100%;
  z-index:9999;
  position:absolute;
  transition: all .5s ease;
  border:1px solid rgb(14, 223, 133);
  opacity: 0.20;
  animation: animation-raya-down 1.15s ease-in-out 0ms 1 normal;
  border-radius: 100px;
  

} 

  
  
  



@keyframes animation-raya-down {
  0% {
      opacity:0;
      width: 0;
      
  }
  100% {
      width: 100%;
      opacity: 0.20;
  }

} 




///// aqui vamos a desbaratar con el grid


.grid-container-depositos{

  display: grid;
  grid-auto-rows: minmax(30px, auto);
  grid-template-columns:repeat(3,1fr) !important;  
  
  



  @media screen and(min-width:0px) and (max-width:500px) {

    grid-template-columns:repeat(2,1fr) !important;
    grid-auto-rows: minmax(50px, auto);
    column-gap: 15px; 
    row-gap: 15px; 
    height: 270px !important;
    padding-right: 10px;
    padding-left: 10px;

  } 

  
  @media screen and(min-width:500px) and (max-width:768px) {

    grid-template-columns:repeat(3,1fr) !important;
    grid-auto-rows: minmax(50px, auto);
    column-gap: 5px; 
    row-gap: 5px; 
    height: 270px !important;
    padding-right: 0px;
    padding-left: 0px;

  }


}


.grid-container {
  display: grid;
  grid-auto-rows: minmax(30px, auto);
  grid-template-columns:repeat(3,1fr);
  column-gap: 5px; 
  
  height: 120px;
  padding: 10px;
  text-align: center;
  width: 100%;
  




  @media screen and(min-width:0px) and (max-width:500px) {

    grid-template-columns:repeat(2,1fr);
    grid-auto-rows: minmax(50px, auto);
    column-gap: 15px; 
    row-gap: 15px; 
    height: 220px;
    padding-right: 10px;
    padding-left: 10px;

  } 

  
  @media screen and(min-width:500px) and (max-width:768px) {

    grid-template-columns:repeat(2,1fr);
    grid-auto-rows: minmax(50px, auto);
    column-gap: 5px; 
    row-gap: 5px; 
    height: 220px;
    padding-right: 0px;
    padding-left: 0px;

  }




  
  div {
    border:1px solid #2956ac;
    background:linear-gradient(to bottom left, #00113e, #001241d1);
    border-radius: 3px;
    padding:  10px;
    align-items: center;
    display: flex;
    justify-content: center;
    transition: all 500ms ease;
    align-items: center;
    flex-wrap: wrap;
    cursor:pointer; 
    align-content: center;
    

    

  } 


  div:hover {
    
    background:#0b2b83;
    transition: all 500ms ease;    
    

  }

  span{
    font-size: 10px;
    color: #fff;
    width: 100%;
  }

p {
  font-size: 20px;
  margin: 0px !important;
  width: 100%;
  line-height: 19px !important;
  transition: all 500ms ease;
  

}
p:hover {
  font-size: 20px;
}

} 

.active-selection {
  
     background: linear-gradient(to left, #2538a5cf, #0f306cdb), url(./Panther-pattern-7.png) !important;
    background-position: 50% 60% !important;
    background-size:150% !important;
    background-repeat:no-repeat !important;
  box-shadow: 0px 0px 6px #000 !important;
  border: 1px solid #0066ff !important;
  line-height: 19px !important;
  transition: all 500ms ease;
  transform: scale(1.05);
  border-radius: 8px !important;
  animation: animation-on-hover-bg 28.8s infinite alternate;
  

  

  i {
    display: block !important;
    color: #3e5cff;
    transition: all 500ms ease;
    animation: animation-open 500ms ease-in-out 0ms 1 normal;
  }
  
  




  p{
    color:#a9a9a9;
    font-weight: 300 !important;
    font-size:20px !important;
    animation:animation-open 600ms ease-in-out 0ms 1 normal;
  }
} 




@keyframes animation-on-hover-bg {
  0% {
    background-size:150% !important;
      
      
  }
  100% {
      
    background-size:450% !important;
  }

} 



@keyframes animation-open {
  0% {
      opacity:0;
      
      
  }
  100% {
      
      opacity: 1;
  }

} 




.retiro-type-fix {
  font-size:35px !important;
  text-align: center !important; 
  text-shadow:0px 0px 10px #000 !important;
  

  @media screen and(min-width:500px) and (max-width:768px) {

    font-size:20px !important;

  }

  @media screen and(min-width:0px) and (max-width:500px) {
    font-size:20px !important;
   
  }

  

} 

.pantherplus{
  color: rgb(0, 247, 161) !important;
  font-size:15px !important;
} 


.panther-icon-style {

  background:#0000;
  font-size: 20px !important;
  color: #ffff;
  margin-bottom:6px;
  transition: all 500ms ease;
  display: none !important;
  
}
 

.master-click {
  color:rgb(0, 247, 161) !important; 
  cursor: pointer;
} 


.paypal-font {
  font-size: 20px !important;
  text-align: center;
  padding: 00px 0px 0px 0px !important;
  line-height: 21px;
  width: 100%;
  margin: 0px !important;
} 


.container-fluid {
  
  margin: 0px !important;
  padding: 0px !important;
} 


.pantherpassword i {
  font-size: 14px !important; 
  text-align: center;
  align-items: center;
  
  background: #0b2b8314;
  
  padding: 3px !important;
  border-radius: 2px !important;
  display: grid;
  
  right:20px;
  width: 27px;
  height: 27px;
  
  color: #0b2b83;
  position: absolute;
  cursor:pointer;
  z-index: 1;
  transition: all 500ms ease;
  box-shadow: 0px 0px 2px rgb(9, 62, 221) !important;

} 


.pantherpassword i:hover {
  background: #005eff !important;
  transition: all 500ms ease;
  color: #fff;
  border:1px solid #005eff;
  
  
}



.pantherpassword {
  position: relative;
  display: flex;
} 


.icon-vis-style {
  width:100% !important;
  height: 100% !important;
} 

.mercado-tittle {

  h2 {
    text-align:center;
    margin:0px !important
  }

  p {

    border-top:1px solid #363a3f;
    text-align:center;
  }


} 

.pagination-style {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
  text-shadow: 0px 0px 4px #000;
  color: #fff;
  border-bottom:1px solid #ae7d00;
  border-top:1px solid #ae7d00;
  background: rgba(0, 0, 0, 0.461);
  margin-top: 10px;
  margin-bottom: 10px;
  filter: saturate(60%);
  

  strong {
    font-size: 17px;
    margin: 5px;
    color: #ffcb00 !important;
    filter: saturate(50%);
    text-shadow: 0px 0px 4px #000;
    transition: all 500ms ease;
    font-weight: 900;
  }
     
    strong:hover {
      font-size: 30px;
      transition: all 500ms ease;
      cursor:zoom-in;
    }
  


  span {
    margin: 5px;
    font-size: 17px;
    color: #ffcb00 !important;
    filter: saturate(50%);
    font-weight: 900;
    text-shadow: 0px 0px 4px #000;
  }
}


.descripcion-fix{
  margin-top: 7px;
  border:1px solid rgba(14, 76, 201, 0.55);
  height: 120px;
  position: relative;
  overflow-y: scroll;
  padding-top: 18px;
  padding-right: 18px;
  padding-left: 18px;
  text-align: left;
  border-radius: 7px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.413); 
  

  

  p {
    font-weight: 900;
  }

  @media screen and(min-width:0px) and (max-width:992px) {
    height:200px !important;
    font-weight: 300 !important;

    strong {
      font-weight: 900 !important;
      font-size: 30px;
    }
   
  }


} 

.invertir-btn-responsive {
  
  display: grid;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  
} 




