


body {
    overflow-x: hidden !important;
}






@media screen and(min-width:0px) and (max-width:992px) {

    .form-fix-login {
        height:800px !important;
    }
    
    .form-fix {
        height: 1300px !important;
    }
    
  }




.contenedor-form {
    margin: 0em 5rem;
    padding: 1rem 3rem;
    max-width: 520px;
    width: 90%;
    background: #fff;
    border-radius: 7px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
    //  margin-top: 50px;

    @media screen and(min-width:0px) and (max-width:992px) {

        
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 5rem 1rem;
        border-radius: 0px;
        height: 100%;
        
        margin-left:auto;
        margin-right:auto;
      }


     

    
} 


.campo-form-panther-send {
    background:linear-gradient( to bottom right, rgb(255, 255, 255),rgb(222, 222, 222));
    padding-left: 20px !important;

    
    flex: 0 0 10px !important;
    box-shadow: 0px 0px 14px #000;
    color: rgb(0, 0, 0);
    min-width:60% !important; 

} 



.text-help-send {
    font-size: 30px !important;
}

.campo-form-panther {
    background: #fff;
    padding-left: 20px !important;
    flex: 0 0 10px !important;
    // box-shadow: 0px 0px 14px rgb(237, 237, 237);
    color: rgb(0, 0, 0);
    transition: all 500ms ease;
     

    
    @media screen and(min-width:0px) and (max-width:500px) {
        background:#fff;
        box-shadow:0px 0px 5px #c0c0c04f;
        transition: all 500ms ease;

        

        label {
            width: 100%;
        }

        
        input {
            width: 100%;
        }



        
      }

    @media screen and(min-width:0px) and (max-width:400px) {
        
      }

      @media screen and(min-width:0px) and (max-width:350px) {
        
      }
      

      @media screen and(min-width:0px) and (max-width:300px) {
        
      }
      @media screen and(min-width:0px) and (max-width:200px) {
        
      }

}

.campo-form-panther2 {
    background:#8fd5ffa3;
    padding-left: 20px !important;
}

.sombra-dark {
    box-shadow: 0px 6px 11px -8px rgba(0, 0, 0, 0.9);
} 

.alerta-panther {
    text-align:center;
    border: 1px solid rgb(201, 201, 201);
    width:100%;
    transition: all 50ms ease-in-out !important;
    margin-bottom: 40px;
    border-radius: 40px;
    color:#a3161f;
    background: #fff;

}



.form-usuario {
    background: linear-gradient(to bottom left, rgba(26, 31, 44, 0.99), rgba(0, 0, 0, 0), rgba(6, 37, 210, 0.57)), url(./bg-dark.jpg);
        background-repeat: repeat, repeat;
        background-size: auto, auto;
    height: 100vh;
    // min-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all 400ms ease !important;
    filter: saturate(50%);


    flex-direction: column;



    @media screen and(min-width:0px) and (max-width:992px) {
        height: 100%;
        align-items: normal;
        min-height: 800px;
        filter: saturate(100%);
        
      }

} 

.form-usuario:hover {
    filter: saturate(70%);
} 




.login-font {
    border-top: 1px solid #e2e2e2b0;
    padding-top: 5px;
    margin-top: 5px;
    color: #0b2b83;
    text-align:center;
}

.campo-form {
    display: flex;
    margin-bottom: 1rem;
    align-items: center; 
    transition: all 500ms ease;
    justify-content: center;

    
    


    @media screen and(min-width:0px) and (max-width:500px)
    {
        display: flex;
        margin-bottom: 1rem;
        align-items: center;
        
        display: flex;
        flex-direction: column;
    }

    
 } 


//  @keyframes autofill {
    
//      0% {
//         color: #666;
//         background: transparent;
//         box-shadow: none;
//         border: 1px solid #1948c9;
//     }
    
//     100% {
//         color: #666;
//         background: transparent;
//         box-shadow: none;
//         border: 1px solid #1948c9;
//     }
// }

// input:-webkit-autofill
//  {
//     animation-delay: 1s; /* Safari support - any positive time runs instantly */
//     animation-name: autofill;
//     animation-fill-mode: both;
// }


input[autocomplete]:focus {
    outline: none;
    background: #ffffff !important;
    border:1px solid #000;
    transition: all 500ms ease;
}





.campo-form input {
    border:1px solid #a6a6a6;
    padding: 0.7rem;
    flex: 1 1;
    border-radius: 2px;
    transition: all 500ms ease;

    input:-webkit-autofill {
        background:#fff !important;
    }
    

    @media screen and(min-width:0px) and (max-width:500px)
    {
        outline: none !important;
        padding: 0.950rem;
    }

} 



// cuando le pones la atencion sobre el
.campo-form input::placeholder {
    transition: all 500ms ease;
    @media screen and(min-width:0px) and (max-width:500px)
    { 
        transition: all 500ms ease;
        transition: all 500ms ease;
    border-radius: 100px !important;
    outline: none !important;
    
    }

} 




.campo-form input:focus {
    @media screen and(min-width:0px) and (max-width:500px)
    {
        outline: none !important;
        box-shadow: 0px 0px 7px rgb(154, 154, 154);
        transition: all 500ms ease;
    background: rgb(255, 255, 255) !important;
    
    transition: all 500ms ease;

    }

}


.campo-form label {
    flex: 0 0 120px;
    font-family: var(--textFont);
    

    @media screen and(min-width:0px) and (max-width:500px) {
        flex: 0 0 15px;
        font-size: 20px;
      }
}



.btn-primario {
    background: #0b2b83;
    color: #fff;
    transition: ease-in-out 250ms;
} 


.btn {
    margin-top: 2rem;
    font-family: var(--headingFont);
    padding: 1.5rem;
    font-size: 1.4;
    font-weight: 400;
    border-radius: 100px;
    border: none;
    transition: background-color .3s ease;
} 

.enlace-cuenta {
    
    display: inline-block;
    opacity: .7;
    display:flex;
    flex-direction: row;
    justify-content: center;
    color: #525f7f;
    font-size:18px;
    text-align:center;

    span:nth-child(1) {
        font-weight: 700;
    }

    p{
        color: #2b313f;
        font-size: 13px;
    } 

    .entrar{
        color: #0b2b83;
        font-weight: 900;
        transition: alll 500ms ease;
    }

    .entrar:hover{
        color: #1948c9;
        font-weight: 900;
        transition: alll 500ms ease;
    }

  }
  

  
.enlace-cuenta:hover {
    
    color: $dark;
    
  }


  .text-grays{
    color: #636f7b !important;
  } 


  .correo-fix{
      line-height: 12px;
  } 


.btn-login {
    padding: 20px 0px 20px 0px !important;
    border-radius: 2px !important;
}

  .font-fix-register{

      font-weight:900;
      font-family: Playfair Display,serif;
        color:#575757;
        font-size: 45px;   
      

      
    @media screen and(min-width:0px) and (max-width:500px)
    {
        font-family: Playfair Display,serif;
        color:#575757;
        font-size: 44px;   
    }
  } 

  .editar-help {
      flex-direction: column;
  } 

  .bold-clasic {
      font-weight: 900 !important; 
  } 

  .kyc-text {
    
    p {
        text-align:left;
    }

    h3 {
        font-size: 29px;
        color :rgb(62, 199, 129);
        font-weight: 700;
    }
  } 

  .kyc-icon{
      font-size: 120px;
      font-weight: 300 !important; 
      color: #fff;
  } 


  .text-fix-cuento {
      text-align: center;
      width: 100%;
      display: block;
      margin-top: 25px;
      font-weight: 900;
  } 

  .class-black {
      background: rgb(0, 0, 0);
      padding: 6px !important;
  } 

  .text-black {
        font-size: 20px;
        color : rgb(116, 116, 116) !important;
        flex-direction: column !important;
        display: flex ;
        font-weight: 300;

        i {
            color : #0d4de4;
            font-weight: 900;
            
            padding-bottom: 10px;
        }
  } 


  .d-flex {
      display: flex !important;
  } 


  .Hola {
      color: #fff;
      position: relative;
      background: rgba(0, 0, 0, 0.372);
      border:1px solid #0d4de4;
      bottom:0;
      right:0;
      font-size: 30px !important;
      letter-spacing: 3px;
      text-transform:uppercase !important ;
      padding: 30px;
      margin-top:20px;
      width: 100%;
      text-align: center;
      animation:bg-tooltips 1000ms ease-in-out 0ms infinite alternate; 

      @media screen and(min-width:0px) and (max-width:500px) {
        margin-top:0px;
        
      }
  } 

  .Hola2 {
    color: #fff;
    position: relative;
    background: rgba(0, 0, 0, 0.372);
    border:1px solid #0d4de4;
    
    margin: 0px !important;
    font-size: 20px !important;
    letter-spacing: 3px;
    text-transform:uppercase !important ;
    padding-left: 50px;
    padding-right: 50px;
    animation:bg-tooltips 1000ms ease-in-out 0ms infinite alternate;
    display: grid;
    align-items: center; 

    @media screen and(min-width:0px) and (max-width:500px) {
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;
        line-height: 20px !important;
        font-size: 10px !important;

        
      }


    
    
}   


.bienvenido-2 {
    font-size: 17px;
    font-weight: 300;
    text-align: right;
    line-height: 16px;
    border-right: 1px solid  rgb(0, 93, 232);
    padding-right: 10px; 
    display: flex;
    flex-direction: row;


    justify-content: space-between; 

    p {
        margin: 0px !important;
        margin-top: 3px !important ;
        border-left: 1px solid  rgb(0, 93, 232);
        padding-left: 15px ;
        font-weight: 900;
        color:#00126c !important;
    } 

    h4 {
        margin: 0px !important;
        margin-top: 3px !important ;
        
        font-weight: 900;
        color:#00126c !important;
    }



 
   strong{
     font-weight: 800;
   } 
 
 
   span {
     font-size: 10px;
     font-weight: 500;
     padding-left: 5px !important ;
     
     
     
 
   }
 
  }   
 

  .welcome-box-active {
    display: grid;
    grid-auto-rows: minmax(6auto, auto);
    grid-template-columns:repeat(2,1fr);
  }


  .welcome-box {
    display: grid;
    grid-auto-rows: minmax(6auto, auto);
    grid-template-columns:repeat(1,1fr);
  }
  
  .salir {
    font-size:16px;
    color:#ffffff !important;
    font-weight: 900;
    display: inline-grid; 
    white-space: nowrap;
    cursor:pointer; 

    @media screen and(min-width:0px) and (max-width:992px) {
        color:#173db1 !important;
        margin-top:40px;
        
      }
} 

.icon-salir {
    font-size: 25px !important;
    font-weight: 900 !important;
    color: rgb(0, 85, 232) !important;
    cursor:pointer;
    @media screen and(min-width:0px) and (max-width:992px) {
        margin-top:40px !important;
      }
} 

.salir-container{
    transition: all 100ms ease;
    background: #0000002f !important;
    border:1px solid #a48034ae ;
    border-radius: 2px;
    padding: 2px 7px 2px 7px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0);
    cursor:pointer;
    display: block;
p {
    margin:0px;
    font-weight: 600;

}

i {
    font-size: 18px !important;
    padding-left: 10px !important;
}

}

.salir-container:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.116);
    transition: all 100ms ease;
            .icon-salir {
                transition: all 300ms ease;
                color: rgb(73, 38, 250) ;
                
            }  
} 


///// aqui estamos colocando el boton
/// para accionar 

.expandnav > .content {
    padding: 160px 10px 10px 10px !important;
    min-height: calc(100vh - 70px);
} 


@media screen and (min-width:0px) and (max-width:992px){
    .expandnav > .content {
        padding: 0px 10px 10px 10px !important;
        min-height: calc(100vh - 70px);
    }
}

.joema {
    
    display: grid;
    justify-content: center;
    position: relative;
    align-items: center;


    img {
        width: 95%;
        display: grid;
        margin-left: auto;
        margin-right: auto;
        filter:hue-rotate(42deg);
        padding: 10px;
        animation: animation-logo-color 2000ms ease 0ms infinite alternate;
    }

    @keyframes animation-logo-color {
        from {
            // filter:hue-rotate(-7deg);
            // opacity: 0.60;
            filter:blur(4px) hue-rotate(32deg);
        }
        to{
            filter:blur(0px) hue-rotate(32deg);
            // filter:hue-rotate(42deg);
            // opacity: 1;
        }



    }



}


.joema-2 {
    
    display: grid;
    justify-content: center;
    position: relative;
    align-items: center;
    width: 180px;
    animation: animation-logo-move 600ms ease 0ms 1 normal;


    img {
        width: 180px;
        display: grid;
        margin-left: auto;
        margin-right: auto;
         filter:hue-rotate(32deg);
         padding-top: 3px;
        padding-right: 15px;
        
    }

    @keyframes animation-logo-move {
        from {
            // filter:hue-rotate(-7deg);
             opacity: 0.04;
            transform: translateX(40px);
        }
        to{
            transform: translateX(0px);
            // filter:hue-rotate(42deg);
             opacity: 1;
        }

    }



}


.collapse-flotante {
    padding-left: 18px !important;
    padding-right: 18px !important;
}


.intente-nuevamente {
    text-align: center;
    color: rgb(255, 255, 255);
    width: 600px !important;
    position: relative;
    display: grid;
    margin-left: auto;
    margin-right: auto;

    min-width: 300px !important;

}

.campo-form-panther-joe {
    display: grid;
    grid-template-columns: 30% 70%;
    row-gap: 10px;
    margin-bottom: 10px;
    
    
    position: relative;
    width: 100% !important;
    

    @media screen and(min-width:0px) and (max-width:992px){
        grid-template-columns: 100%;
        width: 100%;
        margin-bottom: 20px;
        


        label {
            font-size: 20px;
            color: #012591 !important;
        }
        
    }

    label {
        color : rgb(121, 121, 121);
        
        display: grid;
        align-items: center;
        text-align: center;
    }

    .pantherpassword {
        border-style: none;
        
        
    }

    label, input {
            margin:0px !important;
    }

    input {
        border-style: none;
        width: 100%;
        padding-left: 20px !important;
        

    }

    input {
        border:1px solid rgba(167, 167, 167, 0.392);
        border-left:3px solid rgba(2, 103, 255, 0.826) !important;
        border-right:3px solid rgba(2, 103, 255, 0.826) !important;
        padding: 10px 5px 10px 5px;
        border-radius: 2px;
    }

    
    input:-webkit-autofill {
        background:#fff !important;
    }



    .input-panther7 {
        border-style: none;
        border:1px solid rgb(167, 167, 167);
        padding: 10px 5px 10px 5px;

    }
}

.button-jimf {
    display: grid;
    width: 100%;
    position: relative;
    border-style: none;
    background: #0b2b83;
    color: #fff;
    padding: 15px 10px 15px 10px;
    transition: all 100ms ease;
    z-index: 0;
    border-radius: 2px;
}

.button-jimf::before {
    display: grid;
    width: 100%;
    position: absolute;
    top:0;
    right:0;
    height: 100%;
    border-style: none;
    background: linear-gradient( to left, rgb(17, 62, 146),#012ea749 ), url(./Panther-pattern-7.png);
    background-size: 100%;
    
    color: #fff;
    padding: 15px 10px 15px 10px;
    transition: all 100ms ease;
    content: '';
    z-index: -1;
    border-radius: 2px;
}

.button-jimf:hover {
    background: #003eb9dc;
    transition: all 100ms ease;
    border-radius: 2px;
}


.button-jimf:hover::before {
    opacity: 0.50;
    transition: all 100ms ease;
    background-size: 104%;
    border-radius: 2px;
    
}

.text-dark-50 {
    opacity: 0.50;
    color: #000;
}

.Checkpanther {
    display: grid !important;
    width: 80% !important;
    margin-left: auto !important;
    
     
    

    span {
        line-height: 17px !important;
    }
}


@media screen and (min-width:0px) and (max-width: 992px) {

    .Checkpanther {
        display: grid !important;
        width: 85% !important;
        
    
        span {
            line-height: 17px !important;
            text-align: left !important;
        }
    }

}

.control-joe {
    background: rgb(255, 255, 255) !important;
    border-style: none !important;
    border-radius: 2px !important;
    box-shadow: none!important;
    color: rgb(14, 51, 153) !important;
    border: 1px solid rgb(22, 71, 156) !important;
    padding: 10px 40px;
    transition: all 200ms ease;
    
}

.control-joe:hover {
    border: 1px solid rgb(0, 90, 244) !important;
    color: #fff !important;
    background: rgb(14, 51, 153)  !important;
    border-radius: 2px !important;
    transition: all 220ms ease;
}


.control-joe:focus {
    background: rgb(255, 255, 255) !important;
    border-style: none !important;
    border-radius: 2px !important;
    box-shadow: none!important;
    border: 1px solid rgb(85, 147, 255) !important;
    color: rgb(14, 51, 153) !important;
}



.control-joe option{
    background: rgb(255, 255, 255) !important;
    border-style: none !important;
    border-radius: 2px !important;
    color:  rgb(14, 51, 153) !important;
    border: 1px solid rgb(85, 147, 255) !important;
    
}

.logo-box-holder {
    display: grid;
    grid-template-columns: 40% 60% !important;
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding: 0px 10px;


    .style-logo-Logo {
    border-right:1px solid  rgba(14, 51, 153, 0.196) !important;
    border-radius: 0px;
    transform: scale(0.80);
    padding-right: 12px;
}

.style-logo-text {
    padding-left: 12px;
    padding-right: 12px;
    width: 100%;
    position: relative;
    bottom:7px;
    
}


@media screen and(min-width:0px) and (max-width:500px) {
    width: 100%;
    
  }


}


.text-panther-jimf {
    color:#103e9b !important;
}

.mod-registrese{
    padding: 7px 0px;
   margin: 2px 0px;

    border-radius: 2px;
    
    border:1px solid rgb(171, 196, 255);
    transition: all 120ms ease;
    box-shadow:0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);

    
}


.mod-registrese:hover {
    transition: all 120ms ease;
    border:1px solid rgb(31, 96, 247);
    
}

.footer-plus {
    border-bottom:1px solid rgba(25, 132, 255, 0.212);
    padding-bottom: 5px;
    margin-bottom: 15px !important;
}

.mt-30 {
    margin-top: 2px !important;
    font-weight: 400 !important;
    font-size: 10px !important;
    

}

.welcometext-panther {
    color:rgb(145, 145, 145) !important;
    font-size: 8px !important;
    text-align: left;
}


.panther-7-text-footer {
    text-align: center;
    color: rgb(157, 157, 157);
    border-top:1px solid rgba(157, 157, 157, 0.211);
    font-size: 10px;
    padding-top: 4px;
    letter-spacing: 2px;


    strong {
        font-weight: 600;
    }
}